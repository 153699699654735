import { COLORS } from "../../Constants/styles.constants";
import Button from "../Button";
import Text from "../Text";
import * as types from "./Fallback.types";

function Fallback({
  imgSrc,
  heading,
  subText,
  ctaTitle,
  ctaOnClick,
  loading,
  occupyAvailable,
  icon,
  headingStyle,
  iconPosition,
  imgStyle,
  style,
}: types.FallbackProps) {
  return (
    <div
      style={
        occupyAvailable
          ? { width: "100%", height: "75vh", ...style }
          : {
              position: "fixed",
              inset: 0,
              zIndex: 1000,
              backgroundColor: COLORS.WHITE,
              ...style,
            }
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "16px",
          height: "100%",
        }}
      >
        <img
          src={imgSrc}
          style={{ width: "200px", ...imgStyle }}
          alt="fallback"
        />
        <Text
          fontSize="X_LARGE"
          fontWeight="SEMI_BOLD"
          style={{ color: COLORS.GREY500, fontSize: "18px", ...headingStyle }}
        >
          {heading}
        </Text>
        {subText ? <p style={{ color: COLORS.GREY500 }}>{subText}</p> : null}
        {ctaTitle ? (
          <Button
            style={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
            }}
            type="primary"
            text={ctaTitle}
            size="large"
            onClick={ctaOnClick}
            loading={loading}
            icon={icon}
            iconPosition={iconPosition}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Fallback;
