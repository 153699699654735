import React from "react";

interface ContainarizedImageProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  width?: string;
  height?: string;
  alt: string;
  contStyle?: React.CSSProperties;
}

const ContainarizedImage: React.FC<ContainarizedImageProps> = ({
  width = 24,
  height = 24,
  contStyle,
  alt,
  style,
  ...rest
}) => {
  return (
    <div
      style={{
        width,
        height,
        ...contStyle,
      }}
    >
      <img
        alt={alt}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          ...style,
        }}
        {...rest}
      />
    </div>
  );
};

export default ContainarizedImage;
