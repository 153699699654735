import React, { useState } from "react";
import { Button, Modal } from "antd";
import { Area } from "react-easy-crop";
import Cropper from "react-easy-crop";
import { cropImage } from "./cropUtils";

interface ImageCropperProps {
  open: boolean;
  image: string | undefined;
  onComplete: (croppedImage: Promise<string | undefined>) => void;
  onCancel: () => void;
  containerStyle?: React.CSSProperties;
}

export const ImageCropper: React.FC<ImageCropperProps> = ({
  open,
  image,
  onComplete,
  onCancel,
  containerStyle,
  ...props
}) => {
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  return (
    <Modal
      title="Crop Image"
      visible={open}
      onOk={() =>
        onComplete(cropImage(image as string, croppedAreaPixels!, console.log))
      }
      onCancel={onCancel}
      width={600}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="finish"
          type="primary"
          onClick={() =>
            onComplete(
              cropImage(image as string, croppedAreaPixels!, console.log)
            )
          }
        >
          Finish
        </Button>,
      ]}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "400px",
          backgroundColor: "#fff", // White background
          ...containerStyle, // Allow overriding if needed
        }}
      >
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={(_, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels);
          }}
          onZoomChange={setZoom}
          style={{
            containerStyle: {
              backgroundColor: "#fff", // Forces the cropper background to be white
            },
            mediaStyle: {
              backgroundColor: "#fff", // Ensures transparent images have a white background
            },
          }}
          {...props}
        />
      </div>
    </Modal>
  );
};
