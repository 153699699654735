import styled, { CSSProperties } from "styled-components";
import ContainarizedImage from "../ContainarizedImage/ContainarizedImage";

import chevron from "../../Assets/Icons/Chevron.png";
import { COLORS } from "../../Constants/styles.constants";

type CustomNavProps = {
  goBack: () => void;
  title: string;
  contStyle?: CSSProperties;
  navRight?: React.ReactNode;
  withBorder?: boolean;
};

const CustomNav: React.FC<CustomNavProps> = ({
  goBack,
  title,
  contStyle,
  navRight,
  withBorder,
}) => {
  return (
    <>
      <Container
        style={{
          ...contStyle,
          borderBottom: withBorder
            ? `1px solid ${COLORS.GREY100}`
            : "1px solid transparent",
        }}
      >
        <div onClick={goBack}>
          <ContainarizedImage src={chevron} width="17px" alt="back" />
        </div>
        <Title>{title}</Title>
        <div>{navRight}</div>
      </Container>
      <div style={{ height: "80px" }} />
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 21px;
  background-color: ${COLORS.WHITE};
  z-index: 100;
  height: 80px;
  position: absolute;
  max-width: 65vw;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  top: 0;

  @media (max-width: 2400px) {
    width: 45vw;
  }
  @media (max-width: 1100px) {
    width: 65vw;
  }
  @media (max-width: 980px) {
    width: 100%;
    max-width: 100%;
  }
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: #000;
`;

export default CustomNav;
