export const TAG_COLORS = {
  GREEN: {
    BACKGROUND_COLOR: "#EAF7F0",
    TEXT_COLOR: "#35915E",
  },
  GREY: {
    BACKGROUND_COLOR: "#F6F7F8",
    TEXT_COLOR: "#5C6674",
  },
  RED: {
    BACKGROUND_COLOR: "#FDE6E5",
    TEXT_COLOR: "#E91B16",
  },
  PRIMARY: {
    BACKGROUND_COLOR: "#F4F3FF",
    TEXT_COLOR: "#7B6CF0",
  },
  YELLOW: {
    BACKGROUND_COLOR: "#FEF7E3",
    TEXT_COLOR: "#C18C06",
  },
  ORANGE: {
    BACKGROUND_COLOR: "#FEEFE4",
    TEXT_COLOR: "#C05806",
  },
} as const;
