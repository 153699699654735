import React from "react";
import ContainarizedImage from "../ContainarizedImage/ContainarizedImage";
import { ICONS } from "../../Assets/Icons/Icons";

const CopyToClipboard = ({ textToBeCopied }: { textToBeCopied: string }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToBeCopied).then(
      () => {},
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  return (
    <button
      onClick={copyToClipboard}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "30px",
        width: "30px",
        borderRadius: "10px",
        backgroundColor: "white",
        border: "1px solid #ccc",
        cursor: "pointer",
      }}
    >
      <ContainarizedImage
        src={ICONS.copy} // Replace with the path to your copy icon
        alt="Copy"
        width="16px"
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      />
    </button>
  );
};

export default CopyToClipboard;
