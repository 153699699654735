import { http } from "../../Utilities";
import * as CONSTANTS from "./BrandCampaigns.constants";
import * as types from "./BrandCampaigns.types";

export function getCampaigns() {
  return http.get<types.RawMyCampaignResponse>(
    CONSTANTS.API_ENDPOINTS.MY_CAMPAIGNS
  );
}

const PAGE_LENGTH = 10;

export const handleFetchCampaigns = async ({
  pageParam,
}: types.PageParam): Promise<types.CampaignsPaginationListResponse> => {
  const response = await http.get<types.RawMyCampaignResponse>(
    CONSTANTS.API_ENDPOINTS.MY_CAMPAIGNS,
    {
      pagination_offset: pageParam * PAGE_LENGTH,
      pagination_limit: PAGE_LENGTH,
    }
  );

  // Structure the response according to CampaignsPaginationListResponse type
  return {
    response,
    nextPage:
      response.error && response.error.status_code === 404
        ? null
        : response.result.length > 0
        ? pageParam + 1
        : null,
  };
};

export function updateCampaignCreateStatus({
  campaignId,
  status,
}: {
  campaignId: number;
  status: string;
}) {
  return http.put(CONSTANTS.API_ENDPOINTS.CAMPAIGN, {
    id: campaignId,
    updates: {
      creation_status: status,
    },
  });
}
