import { COLORS } from "../../Constants/styles.constants";

export const calcBackgroundColor = (
  numberOfDays: number,
  background?: "white"
) => {
  if (numberOfDays === 1) {
    return {
      backgroundColor: COLORS.RED50,
      color: COLORS.RED500,
    };
  } else if (numberOfDays === 2 || numberOfDays === 3) {
    return {
      backgroundColor: COLORS.ORANGE50,
      color: COLORS.ORANGE500,
    };
  } else {
    return {
      backgroundColor: background === "white" ? COLORS.GREY50 : COLORS.WHITE,
    };
  }
};

export const calculateDaysLeft = (endDate: string): string => {
  const end = new Date(endDate);
  const now = new Date();
  const diffTime = end.getTime() - now.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays === 1) {
    return "1 day left";
  }
  return diffDays > 0 ? `${diffDays} days left` : "Ended";
};

export function convertNumberToCurrency(number: number) {
  let inr = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  return inr.format(number);
}

export function formatDate(dateString: string): string {
  // Split the input string to separate date and time components
  const [datePart] = dateString.split(" ");

  // Split the date part further into year, month, and day
  const [yearStr, monthStr, dayStr] = datePart.split("-");

  // Convert string parts to numbers
  const year = parseInt(yearStr, 10);
  const month = parseInt(monthStr, 10) - 1; // Months are zero-indexed
  const day = parseInt(dayStr, 10);

  // Create a new Date object using components
  const originalDate = new Date(year, month, day);

  // Extract day, month, and year from the Date object
  const formattedDay = originalDate.getDate();
  const formattedMonth = originalDate.getMonth() + 1; // Months are zero-indexed, so we add 1
  const formattedYear = originalDate.getFullYear();

  // Format day and month to ensure two digits
  const formattedDayStr = formattedDay < 10 ? `0${formattedDay}` : formattedDay;
  const formattedMonthStr =
    formattedMonth < 10 ? `0${formattedMonth}` : formattedMonth;

  // Create formatted date string in "dd/mm/yyyy" format
  const formattedDateString = `${formattedDayStr}/${formattedMonthStr}/${formattedYear}`;

  return formattedDateString;
}

export function formatNumberIndian(number: number | string) {
  if (typeof number === "string") {
    number = Number(number);
  }

  let value = new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return value.format(number);
}

export function formatPercentage(input: string): string {
  // Remove the '%' character and parse the string to a float
  let num = parseFloat(input.replace("%", ""));

  // Round to one decimal place
  num = Math.round(num * 10) / 10;

  // Convert the number back to a string
  let formatted = num.toString();

  // If the decimal part is zero, remove it
  if (formatted.endsWith(".0")) {
    formatted = formatted.slice(0, -2);
  }

  // Add the '%' character back
  return formatted + "%";
}

export function truncateArrayToString(
  array: string[],
  visibleValues: number = 2
): [string, string[]] {
  const itemsToShow =
    array.length > visibleValues ? array.slice(0, visibleValues) : array;
  const remainingItems =
    array.length > visibleValues ? array.slice(visibleValues) : [];

  const displayValue = itemsToShow.join(", ");

  return [displayValue, remainingItems];
}

export function truncateString(string: string): [string, string[]] {
  const items = string.split(",").map((niche) => niche.trim());
  const itemsToShow = items.length > 2 ? items.slice(0, 2) : items;
  const remainingItems = items.length > 2 ? items.slice(2) : [];

  const displayValue = itemsToShow.join(", ");

  return [displayValue, remainingItems];
}
