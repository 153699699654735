export const API_ENDPOINTS = {
  RECEIVED_SUBMISSIONS: "/client/brand/received_submissions",
  SUBMISSION: "/submission",
  SUBMISSIONS: "/submissions",
  FEEDBACK: "/feedback",
  COMMENT: "/comment",
  APPLICATION_META: "/meta/view-applications",
} as const;

export const SUBMISSION_STATUS = {
  SUBMITTED: "Submitted",
  REJECTED: "Rejected",
  APPROVED: "Approved",
  VALIDATED: "Validated",
  CHANGE_REQUESTED: "Change Requested",
  POST_UPLOADED: "Posted",
  POST_VERIFIED: "Post Verified",
  POST_VERIFICATION_FAILED: "Post Verification Failed",
  TIMELINE_EXPIRED: "Timeline Expired",
} as const;

export const TABS = {
  ONGOING: "Ongoing",
  COMPLETED: "Completed",
  REJECTED: "Rejected",
} as const;

export const STATUS_MAP_TABS = {
  [TABS.ONGOING]: [
    SUBMISSION_STATUS.VALIDATED,
    SUBMISSION_STATUS.CHANGE_REQUESTED,
    SUBMISSION_STATUS.APPROVED,
    SUBMISSION_STATUS.POST_UPLOADED,
    SUBMISSION_STATUS.POST_VERIFICATION_FAILED,
  ],
  [TABS.COMPLETED]: [SUBMISSION_STATUS.POST_VERIFIED],
  [TABS.REJECTED]: [SUBMISSION_STATUS.REJECTED],
};

export const MODAL_TYPES = {
  APPROVE: "Approve",
  REJECT: "Reject",
  REQUEST_CHANGES: "Request Changes",
} as const;

export const INSIGHT_TYPES = {
  FOLLOWER_COUNT: "follower_count",
  ENGAGEMENT_RATE: "engagement_rate",
  POST_COUNT: "post_count",
} as const;

export const CAMPAIGN_SUBMISSION_QUERY_KEY = "campaignSubmissions";
export const CAMPAIGN_SUBMISSION_PROFILE_QUERY_KEY = "campaignSubmissions";

export const COLOR_MAP: {
  [key: string]: "YELLOW" | "GREY" | "ORANGE" | "GREEN";
} = {
  Validated: "YELLOW",
  "Change Requested": "ORANGE",
  Approved: "GREY",
  Posted: "YELLOW",
  "Post Verification Failed": "GREY",
  "Post Verified": "GREEN",
};
