import { useInfiniteQuery } from "@tanstack/react-query";
import {
  ActionButtons,
  ApplicantProfileContext,
  ApplicationsList,
  ApprovedApplications,
} from "./BrandApplications.HelperComponents";
import {
  ApplicantProfileWrapper,
  ApplicationTabs,
  FullPageContent,
  PageContent,
  PageTitle,
} from "./BrandApplications.styles";
import * as utilities from "./BrandApplications.utilities";
import * as services from "./BrandApplications.services";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as CONSTANTS from "./BrandApplications.constants";
import { useUpdateQueryParams } from "../../Hooks/Router.hooks";
import { ICONS } from "../../Assets/Icons/Icons";
import { ClipLoader } from "react-spinners";
import { COLORS } from "../../Constants/styles.constants";
import Fallback from "../../Components/Fallback";
import { useState } from "react";
import uploadError from "../../Assets/Images/upload_error.png";
import Card from "../../Assets/Images/Card.svg";
import { Campaign } from "./BrandApplications.types";
import Chill from "../../Assets/Images/Chill.svg";

function BrandApplications() {
  const { campaignId } = useParams();
  const updateQueryParams = useUpdateQueryParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openApplicationId, setOpenApplicationId] = useState<null | number>(
    null
  );
  const [error5xx, setError5xx] = useState(false);

  const activeTab =
    searchParams.get("tab") || CONSTANTS.APPLICATION_STATUS.APPLIED;

  const [campaignData, setCampaignData] = useState<Campaign>({} as Campaign);
  const [selectItem, setSelectItem] = useState(false);
  const [message, setMessage] = useState("");

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ["campaignApplications", campaignId, activeTab],
      queryFn: async ({ pageParam = 0 }) => {
        try {
          const response = await services.handleFetchApplications(
            campaignId ? campaignId : "",
            activeTab,
            { pageParam }
          );

          setMessage(response.response.message);

          if (
            response.response.error &&
            response?.response.error?.status_code >= 500
          ) {
            setError5xx(true);
            throw new Error("Server error");
          }

          // Transform the response even if applications is null
          const transformedData = utilities.transformApplicationList(
            response.response
          );

          setCampaignData(transformedData.campaign);

          return {
            data: transformedData,
            nextPage: response.nextPage,
          };
        } catch (error: any) {
          throw error;
        }
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage) => {
        return lastPage.data.applications &&
          lastPage.data.applications.length > 0
          ? lastPage.nextPage
          : null;
      },
    });

  const handleOpenModal = (application_id: number) => {
    setOpenModal(true);
    setOpenApplicationId(application_id);
  };

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ClipLoader color={COLORS.PRIMARY} size={"48px"} />
      </div>
    );
  }

  const allApplications =
    data?.pages.flatMap((page) =>
      page.data.applications ? page.data.applications : []
    ) || [];

  if (error5xx) {
    return (
      <Fallback
        imgSrc={uploadError}
        ctaOnClick={async () => window.location.reload()}
        ctaTitle="Try again"
        loading={false}
        heading="Something is off from our side"
        subText="We're sorry for the inconvenience, please try again"
      />
    );
  }

  return (
    <main style={{ height: "100vh", overflow: "hidden" }}>
      <div
        style={{ display: "flex", alignItems: "center", paddingLeft: "16px" }}
      >
        <img
          alt="arrow-left"
          src={ICONS.arrowLeft}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={() => navigate("/brand/campaigns")}
        />
        <div>
          <PageTitle fontSize="X_LARGE" fontWeight="SEMI_BOLD">
            <p>Applications</p>
          </PageTitle>
          {campaignData ? (
            <div
              style={{
                fontSize: "14px",
                color: COLORS.GREY500,
                lineHeight: "20p",
              }}
            >
              <span style={{ fontWeight: "500" }}>Title:</span>{" "}
              <span style={{ color: "#000", fontWeight: "500" }}>
                {campaignData.title}
              </span>
              {campaignData?.goal ? (
                <span style={{ fontWeight: "500" }}>
                  &nbsp;| Goal:{" "}
                  <span style={{ color: "#000" }}>{campaignData.goal}</span>
                </span>
              ) : null}
              {campaignData?.target_audience?.gender ? (
                <span style={{ fontWeight: "500" }}>
                  &nbsp;| Gender:{" "}
                  <span style={{ color: "#000" }}>
                    {campaignData.target_audience?.gender}
                  </span>
                </span>
              ) : null}
              {campaignData?.target_audience?.age_ranges &&
              campaignData?.target_audience?.age_ranges?.length > 0 ? (
                <span style={{ fontWeight: "500" }}>
                  &nbsp;| Age Groups:{" "}
                  <span style={{ color: "#000" }}>
                    {campaignData.target_audience?.age_ranges?.join(", ")}
                  </span>
                </span>
              ) : null}
              {campaignData?.target_audience?.locations &&
              campaignData?.target_audience?.locations?.length > 0 ? (
                <span style={{ fontWeight: "500" }}>
                  &nbsp;| Locations:{" "}
                  <span style={{ color: "#000" }}>
                    {campaignData.target_audience?.locations
                      .map((curr) => curr.city)
                      ?.join(", ")}
                  </span>
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      {campaignId ? (
        <ApplicationTabs
          onChange={(activeKey) => updateQueryParams({ tab: activeKey })}
          items={[
            {
              key: CONSTANTS.APPLICATION_STATUS.APPLIED,
              label: "Applied",
              children:
                allApplications?.length === 0 || !campaignData ? (
                  <Fallback
                    occupyAvailable
                    imgSrc={Card}
                    ctaOnClick={async () => navigate("/brand/campaigns")}
                    ctaTitle="Go back"
                    loading={false}
                    heading={message || ""}
                  />
                ) : (
                  <PageContent>
                    <ApplicationsList
                      campaignId={campaignId}
                      status={CONSTANTS.APPLICATION_STATUS.APPLIED}
                      data={{
                        applications: allApplications,
                        campaign: campaignData,
                      }}
                      handleIntersection={fetchNextPage}
                      hasNextPage={hasNextPage}
                      onSelect={() => setSelectItem(false)}
                      isFetchingNextPage={isFetchingNextPage}
                    />
                    {selectItem ? (
                      <div style={{ backgroundColor: COLORS.WHITE }}>
                        <Fallback
                          occupyAvailable
                          loading={false}
                          imgSrc={Chill}
                          ctaOnClick={async () => window.location.reload()}
                          heading="Select an application from left pane to view details"
                        />
                      </div>
                    ) : (
                      <div style={{ position: "relative" }}>
                        <ApplicantProfileWrapper>
                          <ApplicantProfileContext type="application" />
                          <div
                            style={{
                              position: "absolute",
                              bottom: 0,
                              right: 0,
                              left: 0,
                              marginRight: "16px",
                              marginBottom: "16px",
                              backgroundColor: COLORS.WHITE,
                              height: "70px",
                            }}
                          >
                            <ActionButtons
                              data={{
                                applications: allApplications,
                                campaign: campaignData,
                              }}
                              onAcceptOrReject={() => {
                                setSelectItem(true);
                              }}
                              showSave={false}
                            />
                          </div>
                        </ApplicantProfileWrapper>
                      </div>
                    )}
                  </PageContent>
                ),
            },
            {
              key: CONSTANTS.APPLICATION_STATUS.ACCEPTED,
              label: "Accepted",
              children:
                allApplications?.length === 0 || !campaignData ? (
                  <Fallback
                    occupyAvailable
                    imgSrc={Card}
                    ctaOnClick={async () => navigate("/brand/campaigns")}
                    ctaTitle="Go back"
                    loading={false}
                    heading={message || ""}
                  />
                ) : (
                  <FullPageContent>
                    <ApprovedApplications
                      data={{
                        applications: allApplications,
                        campaign: campaignData,
                      }}
                      handleIntersection={fetchNextPage}
                      hasNextPage={hasNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                      openModal={handleOpenModal}
                    />
                    <ApplicantProfileContext
                      application_id_prop={openApplicationId}
                      showModal={openModal}
                      onClose={() => {
                        setOpenModal(false);
                        setOpenApplicationId(null);
                      }}
                      modal
                      type="application"
                    />
                  </FullPageContent>
                ),
            },
            {
              key: CONSTANTS.APPLICATION_STATUS.REJECTED,
              label: "Rejected",
              children:
                allApplications?.length === 0 || !campaignData ? (
                  <Fallback
                    occupyAvailable
                    imgSrc={Card}
                    ctaOnClick={async () => navigate("/brand/campaigns")}
                    ctaTitle="Go back"
                    loading={false}
                    heading={message}
                  />
                ) : (
                  <FullPageContent>
                    <ApprovedApplications
                      handleIntersection={fetchNextPage}
                      hasNextPage={hasNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                      data={{
                        applications: allApplications,
                        campaign: campaignData,
                      }}
                      openModal={handleOpenModal}
                      rejected
                    />
                    <ApplicantProfileContext
                      application_id_prop={openApplicationId}
                      showModal={openModal}
                      onClose={() => {
                        setOpenModal(false);
                        setOpenApplicationId(null);
                      }}
                      modal
                      type="application"
                    />
                  </FullPageContent>
                ),
            },
          ]}
          defaultActiveKey={activeTab || CONSTANTS.APPLICATION_STATUS.APPLIED}
        />
      ) : null}
    </main>
  );
}

export default BrandApplications;
