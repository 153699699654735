import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as types from "./BrandSubmissions.types";
import * as services from "./BrandSubmissions.services";
import * as CONSTANTS from "./BrandSubmissions.constants";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { message } from "antd";
import {
  ApplicationSecondaryInfo,
  RejectedSubmissionListWrapper,
  LabelInfoWrapper,
  NextActionWrapper,
  RejectReasonWrapper,
  RequestChangeActionContent,
  SubmissionActionsWrapper,
  SubmissionBasicInfo,
  SubmissionBasicSecond,
  SubmissionFooterWrapper,
  SubmissionHeaderContent,
  SubmissionHeaderWrapper,
  SubmissionItemWrapper,
  SubmissionListWrapper,
  SubmissionProfileContent,
  SubmissionProfileWrapper,
  SubmissionTitleWrapper,
} from "./BrandSubmissions.styles";
import {
  Avatar,
  Button,
  Form,
  FormItem,
  Modal,
  Select,
  Tag,
  Text,
  Skeleton,
} from "../../Components";
import { dateTime } from "../../Utilities";
import React, { useEffect, useRef, useState } from "react";
import { useUpdateQueryParams } from "../../Hooks/Router.hooks";
import { ValueOf } from "../../Utilities/Types";
import * as utilities from "./BrandSubmissions.utilities";
import { ICONS } from "../../Assets/Icons/Icons";
import { COLORS } from "../../Constants/styles.constants";
import {
  InlfuencerDetailButton,
  RequestChanges,
  SubmissionPostVerified,
  SubmissionPosted,
  SubmissionVideoAndDetails,
} from "./BrandSubmissions.sub";
import ContainarizedImage from "../../Components/ContainarizedImage/ContainarizedImage";
import { ClipLoader } from "react-spinners";
import { ApplicantProfileContext } from "../BrandApplications/BrandApplications.HelperComponents";

function LabelInfo({ title, value }: types.LabelInfoProps) {
  return (
    <div>
      <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
        {value}
      </Text>
      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
        {title}
      </Text>
    </div>
  );
}

function RejectedSubmissionItem({
  submission,
  selectedSubmission,
}: types.RejectedSubmissionItemProps) {
  const { partner, insights } = submission;
  return (
    <div>
      <SubmissionItemWrapper $selected={selectedSubmission === submission.id}>
        <SubmissionTitleWrapper>
          <Avatar
            src={`${partner.profile_picture}?a=${new Date()}`}
            alt={partner.name}
            size={40}
          />

          <SubmissionBasicInfo>
            <Text
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "600",
              }}
            >
              {partner.name}
            </Text>
            <SubmissionBasicSecond>
              <Text
                fontSize="X_SMALL"
                color="SECONDARY_TEXT"
                style={{
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: "500",
                }}
              >
                {partner.username}
              </Text>
            </SubmissionBasicSecond>
          </SubmissionBasicInfo>
        </SubmissionTitleWrapper>
        <ApplicationSecondaryInfo>
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <div style={{ height: "16px", width: "16px" }}>
              <img src={ICONS.gender} alt="gender" />
            </div>
            <Text
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "600",
              }}
            >
              {partner?.gender || "N/A"}
            </Text>
          </div>
          {submission.tag ? <Tag color="GREEN">{submission.tag}</Tag> : null}
        </ApplicationSecondaryInfo>
        <LabelInfoWrapper>
          {insights.map((insight, index) => (
            <LabelInfo
              key={index}
              title={insight.label}
              value={insight.value}
            />
          ))}
        </LabelInfoWrapper>
        {submission.next_action ? (
          <NextActionWrapper>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Text fontWeight="SEMI_BOLD" fontSize="X_SMALL">
                  {submission.next_action.label}
                </Text>
                <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
                  {submission.next_action.event.key} on{" "}
                  {dateTime.dateTime.formatDayMonthYear(
                    submission.next_action.event.date
                  )}
                </Text>
              </div>

              {/* {selectedSubmission === submission.id ? null : (
                <img src={ICONS.arrowRightPrimary} alt="arrow-right" />
              )} */}
            </div>
          </NextActionWrapper>
        ) : null}
        <div
          style={{
            paddingTop: "12px",
            borderTop: `1px solid ${COLORS.GREY200}`,
            marginTop: "12px",
          }}
        >
          {submission.rejectionReasons &&
          submission.rejectionReasons.length > 0 ? (
            <div
              style={{
                padding: "8px",
                backgroundColor: COLORS.GREY50,
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <img src={ICONS.StatusWrong} alt="status-wrong" />
              <div>
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {submission.rejectionReasons[0]?.title}
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: COLORS.GREY500,
                  }}
                >
                  {submission.rejectionReasons[0]?.description}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </SubmissionItemWrapper>
    </div>
  );
}

function SubmissionItem({
  submission,
  path,
  selectedSubmission,
  onSelect,
}: types.SubmissionItemProps) {
  const { partner, insights } = submission;

  return (
    <Link to={path} onClick={onSelect}>
      <SubmissionItemWrapper
        $selected={selectedSubmission ? selectedSubmission : false}
      >
        <SubmissionTitleWrapper>
          <Avatar
            src={`${partner.profile_picture}?a=${new Date()}`}
            alt={partner.name}
            size={40}
            style={{ flexShrink: 0 }}
          />
          <SubmissionBasicInfo>
            <Text
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "600",
                overflowWrap: "anywhere",
              }}
            >
              {partner.name}
            </Text>
            <SubmissionBasicSecond>
              <Text
                fontSize="X_SMALL"
                color="SECONDARY_TEXT"
                style={{
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: "500",
                }}
              >
                {partner.username}
              </Text>
            </SubmissionBasicSecond>
          </SubmissionBasicInfo>
        </SubmissionTitleWrapper>
        <ApplicationSecondaryInfo>
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <div style={{ height: "16px", width: "16px" }}>
              <img src={ICONS.gender} alt="gender" />
            </div>
            <Text
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "600",
              }}
            >
              {partner?.gender || "N/A"}
            </Text>
          </div>
          {submission.brand_visible_status ? (
            <Tag color={CONSTANTS.COLOR_MAP[submission.status]}>
              {submission.brand_visible_status}
            </Tag>
          ) : null}
        </ApplicationSecondaryInfo>
        {insights && insights.length > 0 ? (
          <LabelInfoWrapper style={{ marginBottom: "12px" }}>
            {insights.map((insight, index) => (
              <LabelInfo
                key={index}
                title={insight.label}
                value={insight.value}
              />
            ))}
          </LabelInfoWrapper>
        ) : (
          <div style={{ marginBottom: "12px" }} />
        )}

        {submission.next_action ? (
          <NextActionWrapper
            style={
              {
                // backgroundColor:
                //   TAG_COLORS[CONSTANTS.COLOR_MAP[submission.status]]
                //     .BACKGROUND_COLOR,
              }
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{}}>
                <Text
                  style={
                    {
                      // color:
                      //   TAG_COLORS[CONSTANTS.COLOR_MAP[submission.status]]
                      //     .TEXT_COLOR,
                    }
                  }
                  fontWeight="SEMI_BOLD"
                  fontSize="X_SMALL"
                >
                  {submission.next_action.label}
                </Text>
                <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
                  {submission.next_action.event.key} on{" "}
                  {dateTime.dateTime.formatDayMonthYear(
                    submission.next_action.event.date
                  )}
                </Text>
              </div>

              {/* {selectedSubmission === submission.id ? null : (
                <img src={ICONS.arrowRightPrimary} alt="arrow-right" />
              )} */}
            </div>
          </NextActionWrapper>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              padding: "8px",
              // backgroundColor:
              //   TAG_COLORS[CONSTANTS.COLOR_MAP[submission.status]]
              //     .BACKGROUND_COLOR,
              backgroundColor: COLORS.GREY50,
              borderRadius: "4px",
            }}
          >
            <ContainarizedImage
              src={ICONS[submission.message.icon]}
              height="16px"
              width="16px"
              contStyle={{ flexShrink: 0 }}
              alt="icon"
            />
            <p
              style={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "16px",
                color: COLORS.GREY500,
                // color:
                //   TAG_COLORS[CONSTANTS.COLOR_MAP[submission.status]].TEXT_COLOR,
              }}
            >
              {submission.message.text}
            </p>
          </div>
        )}
      </SubmissionItemWrapper>
    </Link>
  );
}

export function OngoingSubmissionList({
  submissions,
  handleIntersection,
  isFetchingNextPage,
  hasNextPage,
  onSelect,
}: types.SubmissionListProps) {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const applicationId = parseInt(params.get("application_id") || "");

  const observerTarget = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          handleIntersection();
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [handleIntersection, hasNextPage, isFetchingNextPage]);

  function getUpdatedUrl(applicationId: number) {
    const newParams = new URLSearchParams(params);
    newParams.set("application_id", applicationId.toString());
    newParams.set("version", "");
    return `${location.pathname}?${newParams.toString()}`;
  }

  return (
    <SubmissionListWrapper>
      {submissions.map((submission) => (
        <SubmissionItem
          onSelect={onSelect}
          key={submission.id}
          submission={submission}
          path={getUpdatedUrl(submission.application_id)}
          selectedSubmission={applicationId === submission.application_id}
        />
      ))}
      <div ref={observerTarget} style={{ height: "20px" }}>
        {isFetchingNextPage && (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              height: "20px",
              marginBottom: "90px",
            }}
          >
            <ClipLoader color={COLORS.PRIMARY} />
            <p>Loading more campaigns</p>
          </div>
        )}
      </div>
    </SubmissionListWrapper>
  );
}

export function CompletedSubmissionList({
  submissions,
  handleIntersection,
  isFetchingNextPage,
  hasNextPage,
}: types.SubmissionListProps) {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const applicationId = parseInt(params.get("application_id") || "");

  const observerTarget = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          handleIntersection();
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [handleIntersection, hasNextPage, isFetchingNextPage]);

  function getUpdatedUrl(applicationId: number) {
    const newParams = new URLSearchParams(params);
    newParams.set("application_id", applicationId.toString());
    newParams.set("version", "");
    return `${location.pathname}?${newParams.toString()}`;
  }

  return (
    <SubmissionListWrapper>
      {submissions.map((submission) => (
        <SubmissionItem
          key={submission.id}
          submission={submission}
          path={getUpdatedUrl(submission.application_id)}
          selectedSubmission={applicationId === submission.application_id}
        />
      ))}
      <div ref={observerTarget} style={{ height: "20px" }}>
        {isFetchingNextPage && (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              height: "20px",
              marginBottom: "90px",
            }}
          >
            <ClipLoader color={COLORS.PRIMARY} />
            <p>Loading more campaigns</p>
          </div>
        )}
      </div>
    </SubmissionListWrapper>
  );
}

export function RejectedSubmissionList({
  submissions,
  handleIntersection,
  isFetchingNextPage,
  hasNextPage,
}: types.RejectedSubmissionListProps) {
  const location = useLocation();
  const updateQueryParams = useUpdateQueryParams();

  const params = new URLSearchParams(location.search);

  const applicationId = parseInt(params.get("application_id") || "");
  const observerTarget = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          handleIntersection();
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [handleIntersection, hasNextPage, isFetchingNextPage]);

  function getUpdatedUrl(applicationId: number) {
    const newParams = new URLSearchParams(params);
    newParams.set("application_id", applicationId.toString());
    newParams.set("version", "");
    return `${location.pathname}?${newParams.toString()}`;
  }

  useEffect(() => {
    if (applicationId) {
      return;
    }
    if (!submissions.length) {
      return;
    }
    updateQueryParams({
      application_id: submissions[0].application_id.toString(),
    });
  }, [applicationId, submissions, updateQueryParams]);

  return (
    <RejectedSubmissionListWrapper>
      {submissions.map((submission) => (
        <RejectedSubmissionItem
          key={submission.id}
          submission={submission}
          path={getUpdatedUrl(submission.application_id)}
        />
      ))}
      <div ref={observerTarget} style={{ height: "20px" }}>
        {isFetchingNextPage && (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              height: "20px",
              marginBottom: "90px",
            }}
          >
            <ClipLoader color={COLORS.PRIMARY} />
            <p>Loading more campaigns</p>
          </div>
        )}
      </div>
    </RejectedSubmissionListWrapper>
  );
}

export function SubmissionHeader({
  latestVersion,
  versions,
}: types.SubmissionHeaderProps) {
  const [searchParams] = useSearchParams();
  const updateQueryParams = useUpdateQueryParams();
  const selectedVersion = searchParams.get("version") || latestVersion;
  const versionOptions = versions
    .map((version) => ({
      label: `Version ${version.version}`,
      value: version.version,
      ...version,
    }))
    .reverse();

  //TODO: Fix types here.
  function renderOptions(option: any) {
    const versionData = option.data as types.SubmissionVersionMeta;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px 2px",
        }}
      >
        <div>
          <Text
            style={{ fontWeight: "700", fontSize: "14px", lineHeight: "20px" }}
          >
            Submission {versionData.version}
          </Text>
          <Text
            style={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "16px",
              color: COLORS.GREY500,
              marginBottom: "12px",
            }}
          >
            Requested for changes on{" "}
            {dateTime.dateTime.formatRelativeTime(versionData.created_at)}
          </Text>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "12px",
            }}
          >
            <ContainarizedImage
              src={versionData.thumbnail}
              alt={versionData.title}
              width="44px"
              height="44px"
              contStyle={{
                borderRadius: "4px",
                overflow: "hidden",
                backgroundColor: COLORS.GREY500,
              }}
            />
            <div>
              <Text
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "20px",
                  marginBottom: "2px",
                }}
              >
                {versionData.title}
              </Text>
              <Text
                style={{
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "16px",
                  marginBottom: "2px",
                  color: COLORS.GREY500,
                }}
              >
                Uploaded:{" "}
                {dateTime.dateTime.formatRelativeTime(versionData.updated_at)}
              </Text>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <ContainarizedImage
              src={ICONS.commentExclaimation}
              alt="comment-exclaimation"
              height={"24px"}
              width={"24px"}
            />
            <Text
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "500",
                color: COLORS.GREY500,
              }}
            >
              {versionData.total_comments || "No"} Comments
            </Text>
          </div>
        </div>
        <img src={ICONS.angleRight} alt="angle-right" />
      </div>
    );
  }
  return (
    <SubmissionHeaderWrapper>
      <SubmissionHeaderContent>
        <Select
          style={{ minWidth: "240px" }}
          dropdownStyle={{ minWidth: "370px" }}
          options={versionOptions}
          value={{ value: `Submission ${selectedVersion}` }}
          onChange={(value) => {
            updateQueryParams({ version: (value as number).toString() });
          }}
          optionRenderer={renderOptions}
        />
      </SubmissionHeaderContent>
    </SubmissionHeaderWrapper>
  );
}

// function BaseComment({
//   createdAt,
//   name,
//   profilePicture,
//   text,
//   videoTimestamp,
// }: types.BaseCommentProps) {
//   return (
//     <BaseCommentWrapper>
//       <Avatar src={profilePicture} alt={name} size={40} />
//       <div>
//         <CommenterMetaInfo>
//           <Text fontWeight="SEMI_BOLD" fontSize="X_SMALL">
//             {name}
//           </Text>
//           {videoTimestamp ? (
//             <Tag color="GREEN">
//               {dateTime.dateTime.formatSecondsToTime(videoTimestamp)}
//             </Tag>
//           ) : null}
//           <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
//             {dateTime.dateTime.formatRelativeTime(createdAt)}
//           </Text>
//         </CommenterMetaInfo>
//         <Text fontSize="X_SMALL">{text}</Text>
//       </div>
//     </BaseCommentWrapper>
//   );
// }

// function SubmissionComment({
//   comment,
//   feedbackId,
//   submission,
// }: types.SubmissionCommentProps) {
//   const [shouldShowReply, setShouldShowReply] = useState(false);

//   const queryClient = useQueryClient();

//   const { mutateAsync: postCreateReply, isPending } = useMutation({
//     mutationFn: services.postCreateReply,
//     onSuccess: () => {
//       setShouldShowReply(false);
//       queryClient.invalidateQueries({
//         queryKey: [
//           CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY,
//           CONSTANTS.CAMPAIGN_SUBMISSION_PROFILE_QUERY_KEY,
//           submission.application_id,
//         ],
//       });
//     },
//   });

//   function onReplySubmit(params: types.ReplyFormData) {
//     postCreateReply({
//       submission_id: submission.id,
//       text: params.reply,
//       feedback_id: feedbackId,
//       parent_comment_id: comment.parent_comment.id,
//     });
//   }

//   return (
//     <SubmissionCommentWrapper>
//       <BaseComment
//         createdAt={comment.parent_comment.created_at}
//         name={comment.parent_comment.name}
//         profilePicture={comment.parent_comment.profile_picture}
//         text={comment.parent_comment.text}
//         videoTimestamp={comment.parent_comment.video_timestamp}
//       />
//       {comment.replies?.map((reply) => (
//         <BaseComment
//           key={reply.id}
//           createdAt={reply.created_at}
//           name={reply.name}
//           profilePicture={reply.profile_picture}
//           text={reply.text}
//         />
//       ))}
//       {shouldShowReply ? (
//         <Form<types.ReplyFormData> onSubmit={onReplySubmit}>
//           <div>
//             <FormItem
//               name="reply"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please add reply",
//                 },
//               ]}
//             >
//               <Input type="textarea" placeholder="Reply to Comment" />
//             </FormItem>
//             <ReplySubmitButtonWrapper>
//               <Button text="Cancel" onClick={() => setShouldShowReply(false)} />
//               <Button
//                 text="Submit"
//                 type="primary"
//                 htmlType="submit"
//                 loading={isPending}
//               />
//             </ReplySubmitButtonWrapper>
//           </div>
//         </Form>
//       ) : (
//         <ReplyButtonWrapper>
//           <Button text="Reply" onClick={() => setShouldShowReply(true)} />
//         </ReplyButtonWrapper>
//       )}
//     </SubmissionCommentWrapper>
//   );
// }

// function SubmissionContentView({
//   submission,
// }: types.SubmissionContentViewProps) {
//   const normalizedCaption = submission.caption.replace(/\/n/g, "\n");
//   return (
//     <SubmissionContentWrapper>
//       <SubmissionContentInfoWrapper>
//         <Avatar
//           src={submission.content.thumbnail}
//           alt={submission.title}
//           shape="square"
//           size={40}
//         />
//         <div>
//           <Text fontWeight="SEMI_BOLD">{submission.title}</Text>
//           <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
//             Uploaded at:{" "}
//             {dateTime.dateTime.formatDateTime(submission.created_at)}
//           </Text>
//         </div>
//       </SubmissionContentInfoWrapper>
//       <CaptionTitle fontWeight="SEMI_BOLD">Caption</CaptionTitle>
//       <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
//         {normalizedCaption.split("\n").map((item, idx) => {
//           return (
//             <React.Fragment key={idx}>
//               {item}
//               <br />
//             </React.Fragment>
//           );
//         })}
//       </Text>
//       <CaptionTitle
//         style={{ paddingBottom: 0, marginBottom: 0 }}
//         fontWeight="SEMI_BOLD"
//       >
//         Hashtags
//       </CaptionTitle>
//       <HastTagText fontSize="X_SMALL" color="SECONDARY_TEXT">
//         {submission.hashtags.join(", ")}
//       </HastTagText>
//     </SubmissionContentWrapper>
//   );
// }

// function SubmissionFeedbackView({
//   feedback,
//   submission,
// }: types.SubmissionFeedbackViewProps) {
//   return (
//     <SubmissionFeedbackViewWrapper>
//       <FeedbackViewHeader>
//         <Text fontWeight="SEMI_BOLD">Add comments on the video timeline</Text>
//         <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
//           Add all comments at once to minimize revision cycles
//         </Text>
//       </FeedbackViewHeader>
//       {feedback ? (
//         <FeedbackCommentsWrapper>
//           {feedback.comments?.map((comment) => (
//             <SubmissionComment
//               key={comment.parent_comment.id}
//               comment={comment}
//               feedbackId={feedback.id}
//               submission={submission}
//             />
//           ))}
//         </FeedbackCommentsWrapper>
//       ) : null}
//     </SubmissionFeedbackViewWrapper>
//   );
// }

// function ApproveLiveContent({ submission }: types.ApproveLiveContentProps) {
//   const [showWrongReasonModal, setShowWrongReasonModal] = useState(false);
//   const queryClient = useQueryClient();

//   const {
//     mutateAsync: updateSubmissionAsWrongPost,
//     isPending: isWrongPostPending,
//   } = useMutation({
//     mutationFn: services.updateSubmissionAsWrongPost,
//     onSuccess: () => {
//       setShowWrongReasonModal(false);
//       queryClient.invalidateQueries({
//         queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
//       });
//     },
//   });

//   const {
//     mutateAsync: updateSubmissionStatus,
//     isPending: isStatusUpdatePending,
//   } = useMutation({
//     mutationFn: services.updateSubmissionStatus,
//     onSuccess: () => {
//       queryClient.invalidateQueries({
//         queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
//       });
//     },
//   });

//   const { data } = useQuery({
//     queryKey: ["applicationMeta"],
//     queryFn: () => services.getApplicationMeta(),
//     select: utilities.transformApplicationMeta,
//   });

//   function handleVerifyPost({ approveConsent }: { approveConsent: boolean }) {
//     if (!approveConsent) {
//       return;
//     }
//     updateSubmissionStatus({
//       submissionId: submission.id,
//       status: CONSTANTS.SUBMISSION_STATUS.POST_VERIFIED,
//     });
//   }

//   function handleWrongPostSubmit(formData: {
//     wrongReason: string[];
//     comment?: string;
//   }) {
//     updateSubmissionAsWrongPost({
//       submissionId: submission.id,
//       reasons: formData.wrongReason,
//       comment: formData.comment,
//     });
//   }

//   const { videoRef } = useHlsPlayer();
//   return (
//     <ApproveLiveContentWrapper>
//       <Text fontWeight="SEMI_BOLD">Verify the post</Text>
//       <ApproveLiveContentContainer>
//         <ApproveContentLeftSection>
//           <Text>Live on Instagram</Text>
//           <VideoPlayer ref={videoRef} src={submission.content.url} controls />
//           <ButtonLikeLink
//             to={submission.post_url}
//             text="Check post on instagram"
//           />
//         </ApproveContentLeftSection>
//         <ApproveContentRightSection>
//           <Text fontWeight="SEMI_BOLD" fontSize="MEDIUM">
//             Verify the post to start getting live insights
//           </Text>
//           <Text color="SECONDARY_TEXT" fontSize="SMALL">
//             Please note that once the post is verified, no more changes could be
//             requested from the influencer. Also, it means an approval from your
//             end for paying out to the influencer.
//           </Text>
//           <Form onSubmit={handleVerifyPost}>
//             <CheckboxContentWrapper>
//               <FormItem
//                 name="approveConsent"
//                 rules={[
//                   {
//                     required: true,
//                     message: "Please acknowledge the live content",
//                   },
//                 ]}
//                 valuePropName="checked"
//               >
//                 <Checkbox>
//                   I acknowledge that the live post matches the submission we
//                   approved.
//                 </Checkbox>
//               </FormItem>
//             </CheckboxContentWrapper>
//             <ApproveLiveActionsWrapper>
//               <Button
//                 text="Wrong link/post?"
//                 onClick={() => setShowWrongReasonModal(true)}
//               />
//               <Button
//                 text="Verify Post"
//                 htmlType="submit"
//                 type="primary"
//                 loading={isStatusUpdatePending}
//               />
//             </ApproveLiveActionsWrapper>
//           </Form>
//         </ApproveContentRightSection>
//       </ApproveLiveContentContainer>
//       <Modal
//         title="Wrong link/post?"
//         open={showWrongReasonModal}
//         onCancel={() => setShowWrongReasonModal(false)}
//         footer={null}
//       >
//         <div>
//           <WrongLinkHintText color="SECONDARY_TEXT" fontSize="SMALL">
//             Are you sure you want to reject this submission? Once rejected the
//             action can not be undone.
//           </WrongLinkHintText>
//           <Form onSubmit={handleWrongPostSubmit}>
//             <FormItem
//               name="wrongReason"
//               label="Select reason(s)"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please add a reason",
//                 },
//               ]}
//             >
//               <Select
//                 options={data?.wrongPostReasons || []}
//                 placeholder="Select reason(s)"
//                 mode="multiple"
//               />
//             </FormItem>
//             <FormItem name="comment" label="Additional Comments">
//               <Input type="textarea" placeholder="Add comment" />
//             </FormItem>
//             <SubmitActionButtons
//               okayText="Request Changes"
//               closeModal={() => setShowWrongReasonModal(false)}
//               isPending={isWrongPostPending}
//               okayHtmlType="submit"
//             />
//           </Form>
//         </div>
//       </Modal>
//     </ApproveLiveContentWrapper>
//   );
// }

export function SubmitActionButtons({
  closeModal,
  onOkay,
  okayText,
  isPending,
  okayHtmlType,
  disabled,
  buttonHeight,
}: types.SubmitActionButtonsProps) {
  return (
    <RequestChangeActionContent>
      <Button
        text="Cancel"
        onClick={closeModal}
        style={{
          height: buttonHeight,
        }}
      />
      <Button
        text={okayText}
        type="primary"
        onClick={onOkay}
        loading={isPending}
        htmlType={okayHtmlType}
        disabled={disabled}
        style={{
          height: buttonHeight,
        }}
      />
    </RequestChangeActionContent>
  );
}

// function RequestChanges({ submission, closeModal }: types.RequestChangesProps) {
//   const { videoRef } = useHlsPlayer();

//   const [form] = AntdForm.useForm();
//   const [feedbackComment, setFeedbackComment] = useState<
//     types.FirstFeedbackComment[]
//   >([]);
//   const [comment, setComment] = useState("");
//   const queryClient = useQueryClient();

//   const { mutateAsync: postCreateFeedback, isPending } = useMutation({
//     mutationFn: services.postCreateFeedback,
//     onSuccess: () => {
//       closeModal();
//       queryClient.invalidateQueries({
//         queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
//       });
//     },
//   });

//   const [videoCommentTimestamp, setVideoCommentTimestamp] = useState(0);

//   function handleAddFeedback({ comment }: { comment: string }) {
//     setFeedbackComment((prev) => [
//       ...prev,
//       {
//         videoTimestamp: videoCommentTimestamp,
//         text: comment,
//         id: new Date().getTime(),
//         createdAt: new Date().toISOString(),
//       },
//     ]);
//     setVideoCommentTimestamp(0);
//     form.resetFields();
//   }

//   function handleFeedbackFocus() {
//     if (!videoRef.current) {
//       return;
//     }
//     videoRef.current.pause();
//     setVideoCommentTimestamp(Math.floor(videoRef.current.currentTime));
//   }

//   function onTimeUpdate(event: React.SyntheticEvent<HTMLVideoElement, Event>) {
//     // if the video is playing, we don't want to update the timestamp
//     if (!videoCommentTimestamp) {
//       return;
//     }
//     setVideoCommentTimestamp(Math.floor(event.currentTarget.currentTime));
//   }

//   function handleVideoPause() {
//     setTimeout(() => {
//       if (!videoRef.current || !videoRef.current.paused) {
//         return;
//       }
//       setVideoCommentTimestamp(Math.floor(videoRef.current.currentTime));
//     }, 100);
//   }

//   function handleFeedbackSubmit() {
//     postCreateFeedback({
//       submissionId: submission.id,
//       feedbacks: feedbackComment.map((comment) => ({
//         text: comment.text,
//         videoTimeStamp: comment.videoTimestamp,
//       })),
//     });
//   }

//   return (
//     <RequestChangesMainWrapper>
//       <VideoPlayer
//         src={submission.content.url}
//         controls
//         ref={videoRef}
//         onPlay={() => setVideoCommentTimestamp(0)}
//         onTimeUpdate={onTimeUpdate}
//         onPause={handleVideoPause}
//       />
//       <SubmissionFeedbackViewWrapper>
//         <FeedbackViewHeader>
//           <Text fontWeight="SEMI_BOLD">Add comments on the video timeline</Text>
//           <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
//             Add all comments at once to minimize revision cycles
//           </Text>
//         </FeedbackViewHeader>
//         <FeedbackCommentsWrapper>
//           {feedbackComment.map((comment) => (
//             <FirstFeedbackCommentItem key={comment.id}>
//               <BaseComment
//                 createdAt={comment.createdAt}
//                 name={"You"}
//                 profilePicture={""}
//                 text={comment.text}
//                 videoTimestamp={comment.videoTimestamp}
//               />
//             </FirstFeedbackCommentItem>
//           ))}
//           <Form onSubmit={handleAddFeedback} form={form}>
//             <FormItem
//               name="comment"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please add comment",
//                 },
//               ]}
//             >
//               <Input
//                 type="textarea"
//                 placeholder="Add you comment here"
//                 onFocus={handleFeedbackFocus}
//                 value=""
//                 onChange={(e) => setComment(e.target.value)}
//               />
//             </FormItem>

//             <FirstFeedbackCommentSubmit>
//               <Button
//                 text={`Comment ${dateTime.dateTime.formatSecondsToTime(
//                   videoCommentTimestamp
//                 )}`}
//                 htmlType="submit"
//                 disabled={!comment}
//               />
//             </FirstFeedbackCommentSubmit>
//           </Form>
//         </FeedbackCommentsWrapper>
//       </SubmissionFeedbackViewWrapper>
//       <RequestChangeActionsWrapper>
//         <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
//           Please watch the entire video to submit the feedback. We aim at
//           reducing revision cycles{" "}
//         </Text>
//       </RequestChangeActionsWrapper>
//       <SubmitActionButtons
//         okayText="Submit Feedback"
//         onOkay={handleFeedbackSubmit}
//         closeModal={closeModal}
//         isPending={isPending}
//         disabled={feedbackComment.length === 0}
//       />
//     </RequestChangesMainWrapper>
//   );
// }

function ApproveSubmissionModal({
  closeModal,
  submission,
  onAcceptOrReject,
}: types.ApproveSubmissionModalProps) {
  const queryClient = useQueryClient();

  const { mutateAsync: updateSubmissionStatus, isPending } = useMutation({
    mutationFn: services.updateSubmissionStatus,
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({
        queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
      });
      if (onAcceptOrReject) {
        onAcceptOrReject();
      }
    },
  });

  function handleApprove() {
    updateSubmissionStatus({
      submissionId: submission.id,
      status: CONSTANTS.SUBMISSION_STATUS.APPROVED,
    });
  }

  return (
    <div>
      <Text
        color="SECONDARY_TEXT"
        fontSize="X_SMALL"
        style={{ marginBottom: "24px" }}
      >
        Are you sure you want to approve this submission? Once approved, the
        action cannot be undone.
      </Text>
      <SubmitActionButtons
        okayText="Approve Submission"
        onOkay={handleApprove}
        closeModal={closeModal}
        isPending={isPending}
      />
    </div>
  );
}

function RejectSubmissionModal({
  closeModal,
  submission,
  onReject,
  onAcceptOrReject,
}: types.RejectSubmissionModalProps) {
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ["applicationMeta"],
    queryFn: () => services.getApplicationMeta(),
    select: utilities.transformApplicationMeta,
  });

  const { mutateAsync: updateSubmissionStatus, isPending } = useMutation({
    mutationFn: services.updateSubmissionStatus,
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({
        queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
      });
    },
  });

  const [selectedReason, setSelectedReason] = React.useState<string | null>(
    null
  );

  function handleRejectSubmit(formData: { rejectReason: string }) {
    const rejectionReason = data?.rejectionReasons.find(
      (reason) => reason.value === formData.rejectReason
    );
    if (!rejectionReason) {
      return;
    }
    updateSubmissionStatus({
      submissionId: submission.id,
      status: CONSTANTS.SUBMISSION_STATUS.REJECTED,
      rejectReason: {
        title: rejectionReason.value,
        description: rejectionReason.description,
      },
    });
    if (onAcceptOrReject) {
      onAcceptOrReject();
    }
    if (onReject) {
      onReject();
    }
    message.error('Submission moved to "Rejected"');
  }

  return (
    <>
      <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
        Are you sure you want to reject this submission? Once rejected, the
        action cannot be undone.
      </Text>

      {data ? (
        <>
          <RejectReasonWrapper>
            <Text fontWeight="SEMI_BOLD" fontSize="SMALL">
              Reason for rejection*
            </Text>
            <Form onSubmit={handleRejectSubmit}>
              <FormItem name="rejectReason">
                <Select
                  options={data?.rejectionReasons || []}
                  placeholder="Select a reason"
                  optionRenderer={({ label, value }) => (
                    <div>
                      <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
                        {value}
                      </Text>
                      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
                        {label}
                      </Text>
                    </div>
                  )}
                  onChange={(value: unknown) => {
                    const selectedOption = value as string | null; // Cast value to your option type
                    setSelectedReason(selectedOption);
                  }}
                />
              </FormItem>
              <SubmitActionButtons
                okayText="Reject Submission"
                closeModal={closeModal}
                isPending={isPending}
                okayHtmlType="submit"
                disabled={!selectedReason}
              />
            </Form>
          </RejectReasonWrapper>
        </>
      ) : null}
    </>
  );
}

// function SubmissionMain({ feedback, submission }: types.SubmissionMainProps) {
//   const hasGivenFeedback = !!feedback?.comments?.length;
//   const { videoRef } = useHlsPlayer();
//   return (
//     <SubmissionMainWrapper>
//       <VideoPlayer src={submission.content.url} controls ref={videoRef} />
//       {hasGivenFeedback ? (
//         <SubmissionFeedbackView feedback={feedback} submission={submission} />
//       ) : (
//         <SubmissionContentView submission={submission} />
//       )}
//       <div style={{ height: "70px" }} />
//     </SubmissionMainWrapper>
//   );
// }

function SubmissionActions({
  submission,
  onAcceptOrReject,
  onReject,
}: types.SubmissionActionsProps) {
  const [modalType, setModalType] = useState<ValueOf<
    typeof CONSTANTS.MODAL_TYPES
  > | null>(null);

  function closeModal() {
    setModalType(null);
  }

  function getModalContent() {
    if (modalType === CONSTANTS.MODAL_TYPES.REJECT && onAcceptOrReject) {
      return {
        title: "Confirm Rejection",
        content: (
          <RejectSubmissionModal
            closeModal={closeModal}
            submission={submission}
            onReject={onReject}
            onAcceptOrReject={onAcceptOrReject}
          />
        ),
      };
    }
    if (modalType === CONSTANTS.MODAL_TYPES.REQUEST_CHANGES) {
      return {
        title: `Add your feedback on Submission-${submission.version}`,
        content: (
          // <RequestChanges submission={submission} closeModal={closeModal} />
          <RequestChanges submission={submission} closeModal={closeModal} />
        ),
      };
    }
    return {
      title: "Confirm Approval",
      content: (
        <ApproveSubmissionModal
          closeModal={closeModal}
          submission={submission}
          onAcceptOrReject={onAcceptOrReject}
        />
      ),
    };
  }

  const { content, title } = getModalContent();
  return (
    <SubmissionActionsWrapper>
      <Button
        text="Reject"
        onClick={() => setModalType(CONSTANTS.MODAL_TYPES.REJECT)}
      />
      <Button
        text="Request Changes"
        onClick={() => setModalType(CONSTANTS.MODAL_TYPES.REQUEST_CHANGES)}
      />
      <Button
        text="Approve"
        type="primary"
        onClick={() => setModalType(CONSTANTS.MODAL_TYPES.APPROVE)}
      />
      {modalType === CONSTANTS.MODAL_TYPES.REQUEST_CHANGES ? (
        <Modal
          open={!!modalType}
          onCancel={closeModal}
          title={null}
          centered
          width={
            modalType === CONSTANTS.MODAL_TYPES.REQUEST_CHANGES
              ? "80vw"
              : "500px"
          }
          closable={false}
          footer={null}
          contentStyle={{
            padding: 0,
            backgroundColor: COLORS.GREY50,
            maxHeight: "90vh !important",
          }}
        >
          {content}
        </Modal>
      ) : (
        <Modal
          open={!!modalType}
          onCancel={closeModal}
          title={title}
          centered
          footer={null}
        >
          {content}
        </Modal>
      )}
    </SubmissionActionsWrapper>
  );
}

function SubmissionProfile({
  applicationId,
  onAcceptOrReject,
  onReject,
  onVerifyPost,
}: types.SubmissionProfileProps) {
  const [searchParams] = useSearchParams();
  const version = searchParams.get("version") || "";
  const updateQueryParams = useUpdateQueryParams();
  const [showInfluencerDetails, setShowInfluencerDetails] = useState(false);

  const { data, isPending } = useQuery({
    queryKey: [
      CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY,
      CONSTANTS.CAMPAIGN_SUBMISSION_PROFILE_QUERY_KEY,
      applicationId,
      version,
    ],
    queryFn: () =>
      services.getSubmissionDetails({
        applicationId,
        ...(version && { version }),
      }),
  });

  function getSubmissionContent() {
    if (!data) {
      return null;
    }
    const submissionStatus = data.result.submission.status;
    const latestVersion = data.result?.latest_version || 1;

    switch (submissionStatus) {
      case CONSTANTS.SUBMISSION_STATUS.VALIDATED:
        return (
          <div
            style={{
              paddingRight: "16px",
              paddingTop: "16px",
              backgroundColor: COLORS.GREY50,
            }}
          >
            <SubmissionProfileWrapper>
              <SubmissionVideoAndDetails
                feedback={data.result.feedback}
                submission={data.result.submission}
                versions={data.result.versions ? data.result.versions : []}
                latestVersion={data.result?.latest_version}
                partner={data.result.partner}
              />

              {!version || latestVersion === parseInt(version) ? (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    left: 0,
                    marginRight: "16px",
                    marginBottom: "16px",
                    backgroundColor: COLORS.WHITE,
                    height: "70px",
                  }}
                >
                  <SubmissionActions
                    submission={data.result.submission}
                    onReject={onReject}
                    onAcceptOrReject={onAcceptOrReject}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    width: "100%",
                    padding: "12px",
                    justifyContent: "center",
                  }}
                >
                  <ContainarizedImage
                    src={ICONS.info}
                    height="14px"
                    width="14px"
                    alt="info"
                  />
                  <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
                    You are viewing previous version,{" "}
                    <span
                      style={{
                        color: COLORS.PRIMARY,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        updateQueryParams({
                          version: (
                            data.result?.latest_version as number
                          ).toString(),
                        });
                      }}
                    >
                      go to the latest version
                    </span>
                  </Text>
                </div>
              )}
            </SubmissionProfileWrapper>
          </div>
        );

      case CONSTANTS.SUBMISSION_STATUS.CHANGE_REQUESTED:
        return (
          <div
            style={{
              paddingRight: "16px",
              paddingTop: "16px",
              backgroundColor: COLORS.GREY50,
            }}
          >
            <SubmissionProfileWrapper>
              <SubmissionVideoAndDetails
                feedback={data.result.feedback}
                submission={data.result.submission}
                versions={data.result.versions ? data.result.versions : []}
                latestVersion={data.result?.latest_version}
                partner={data.result.partner}
              />
              {!version || latestVersion === parseInt(version) ? (
                <SubmissionFooterWrapper>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <ContainarizedImage
                      src={ICONS.info}
                      height="14px"
                      width="14px"
                      alt="info"
                    />
                    <Text
                      color="SECONDARY_TEXT"
                      fontSize="X_SMALL"
                      style={{ textAlign: "center" }}
                    >
                      The influencer is revising the content; the updated
                      version will appear here once submitted. Meanwhile, you
                      can respond to any messages from the influencer.
                    </Text>
                  </div>
                </SubmissionFooterWrapper>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    width: "100%",
                    padding: "12px",
                    justifyContent: "center",
                  }}
                >
                  <ContainarizedImage
                    src={ICONS.info}
                    height="14px"
                    width="14px"
                    alt="info"
                  />
                  <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
                    You are viewing previous version,{" "}
                    <span
                      style={{
                        color: COLORS.PRIMARY,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        updateQueryParams({
                          version: (
                            data.result?.latest_version as number
                          ).toString(),
                        });
                      }}
                    >
                      go to the latest version
                    </span>
                  </Text>
                </div>
              )}
            </SubmissionProfileWrapper>
          </div>
        );

      case CONSTANTS.SUBMISSION_STATUS.APPROVED:
        return (
          <div
            style={{
              paddingRight: "16px",
              paddingTop: "16px",
              backgroundColor: COLORS.GREY50,
            }}
          >
            <SubmissionProfileWrapper>
              <SubmissionVideoAndDetails
                submission={data.result.submission}
                latestVersion={data.result?.latest_version}
                partner={data.result.partner}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  width: "100%",
                  padding: "12px",
                  justifyContent: "center",
                }}
              >
                <ContainarizedImage
                  src={ICONS.info}
                  height="14px"
                  width="14px"
                  alt="info"
                />
                <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
                  Once the influencer has posted the content on Instagram, you
                  can view the live post here.
                </Text>
              </div>
            </SubmissionProfileWrapper>
          </div>
        );

      case CONSTANTS.SUBMISSION_STATUS.POST_UPLOADED:
        return (
          <div
            style={{
              paddingRight: "16px",
              paddingTop: "16px",
              backgroundColor: COLORS.GREY50,
            }}
          >
            <SubmissionProfileWrapper>
              <SubmissionPosted
                submission={data.result.submission}
                partner={data.result.partner}
                onVerifyPost={onVerifyPost}
              />
            </SubmissionProfileWrapper>
          </div>
        );

      case CONSTANTS.SUBMISSION_STATUS.POST_VERIFICATION_FAILED:
        return (
          <div style={{ height: "100%", backgroundColor: COLORS.GREY50 }}>
            <ApplicantProfileContext
              type="submission"
              modal
              showModal={showInfluencerDetails}
              application_id_prop={applicationId}
              onClose={() => setShowInfluencerDetails(false)}
            />
            <div
              style={{
                paddingRight: "16px",
                paddingTop: "16px",
                backgroundColor: COLORS.GREY50,
              }}
            ></div>
            <div
              style={{
                backgroundColor: COLORS.WHITE,
                padding: "24px 12px",
                marginRight: "16px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              {data.result.submission.brand_visible_status ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Tag
                    color={CONSTANTS.COLOR_MAP[data.result.submission.status]}
                  >
                    {data.result.submission.brand_visible_status}
                  </Tag>
                  <InlfuencerDetailButton
                    setShowInfluencerDetails={() => {
                      setShowInfluencerDetails(true);
                    }}
                    name={data.result.partner.name}
                  />
                </div>
              ) : null}
            </div>
            <div
              style={{
                height: "calc(100% - 100px)",
                backgroundColor: COLORS.WHITE,
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                marginRight: "16px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "494px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "24px",
                    backgroundColor: COLORS.GREY50,
                    textAlign: "center",
                    borderRadius: "12px",
                  }}
                >
                  <ContainarizedImage
                    alt="clock"
                    src={ICONS.clock}
                    height="48px"
                    width="48px"
                    contStyle={{
                      marginBottom: "27px",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "20px",
                      lineHeight: "32px",
                      fontWeight: "600",

                      marginBottom: "4px",
                    }}
                  >
                    We've requested the influencer to make the necessary changes
                  </p>
                  <p style={{ lineHeight: "22px", color: COLORS.GREY500 }}>
                    Once the influencer has made the edits, you can view the
                    updated post here.
                  </p>
                </div>
              </div>
            </div>
          </div>
        );

      case CONSTANTS.SUBMISSION_STATUS.POST_VERIFIED:
        return (
          <div
            style={{
              paddingRight: "16px",
              paddingTop: "16px",
              backgroundColor: COLORS.GREY50,
            }}
          >
            <SubmissionProfileWrapper>
              <SubmissionPostVerified
                submission={data.result.submission}
                partner={data.result.partner}
                onVerifyPost={() => {}}
              />
            </SubmissionProfileWrapper>
          </div>
        );
    }
  }

  if (isPending) {
    return <Skeleton />;
  }
  if (!data?.result) {
    return null;
  }

  return (
    <SubmissionProfileContent>
      {getSubmissionContent()}
    </SubmissionProfileContent>
  );
}

export function SubmissionProfileContext({
  onAcceptOrReject,
  onReject,
  onVerifyPost,
}: {
  onReject: () => void;
  onAcceptOrReject: () => void;
  onVerifyPost: () => void;
}) {
  const [searchParams] = useSearchParams();
  const applicationId = Number(searchParams.get("application_id") || 0);
  if (!applicationId) {
    return <></>;
  }
  return (
    <SubmissionProfile
      applicationId={applicationId}
      onAcceptOrReject={onAcceptOrReject}
      onReject={onReject}
      onVerifyPost={onVerifyPost}
    />
  );
}
