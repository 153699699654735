import React, { useState } from "react";
import { COLORS } from "../../Constants/styles.constants";

interface TrimmedTextProps {
  text: string;
  maxLength: number;
  contStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

const TrimmedText: React.FC<TrimmedTextProps> = ({
  text,
  maxLength,
  contStyle,
  style,
}) => {
  const [expanded, setExpanded] = useState(false);

  const displayText = expanded ? text : text.slice(0, maxLength);

  return (
    <div
      style={contStyle}
      onClick={() => setExpanded(!expanded)}
      role="button"
      tabIndex={0}
    >
      <span style={{ fontSize: "14px", ...style }}>
        {displayText}
        {maxLength < text.length && !expanded ? "..." : ""}
        {expanded ? (
          <span style={{ color: COLORS.PRIMARY }}> Show Less</span>
        ) : (
          maxLength < text.length && (
            <span style={{ color: COLORS.PRIMARY }}> Show More</span>
          )
        )}
      </span>
    </div>
  );
};

export default TrimmedText;
