import { useQuery } from "@tanstack/react-query";

import * as services from "./BrandHome.services";
import * as types from "./BrandHome.types";
import {
  ActionDescription,
  ActionHeading,
  ActionIconCont,
  BannerCont,
  BannerContent,
  BannerDescription,
  BannerHeading,
  BrandHomePageCont,
  DemoCardCont,
  DemoCont,
  DemoDescription,
  DemoThumbnailCont,
  PendingActionCardCont,
  PendingActionsCont,
  SectionCont,
  SectionDescription,
  SectionHeading,
} from "./BrandHome.styles";
import { Button } from "../../Components";
import { ICONS } from "../../Assets/Icons/Icons";
import { useNavigate } from "react-router-dom";
import VideoPlayerScreen from "../VideoPlayerScreen/VideoPlayerScreen";
import { useState } from "react";

function Home() {
  const { data, isPending } = useQuery({
    queryKey: ["dashboard"],
    queryFn: () => services.getDashboard(),
  });

  const [videoUrl, setVideoUrl] = useState("");
  const navigate = useNavigate();

  const dashboard = data?.result;

  return isPending ? (
    <></>
  ) : (
    <BrandHomePageCont>
      {/* PENDING ACTIONS */}
      <VideoPlayerScreen
        open={Boolean(videoUrl)}
        onCancel={() => {
          setVideoUrl("");
        }}
        url={videoUrl}
      />
      {dashboard?.actions && dashboard?.actions?.cards?.length > 0 ? (
        <SectionCont>
          <SectionHeading>{dashboard?.actions.label}</SectionHeading>
          <SectionDescription>
            {dashboard?.actions.description}
          </SectionDescription>
          <PendingActionsCont>
            {dashboard?.actions?.cards.map((curr, i) => {
              return <PendingActionCard card={curr} key={i} />;
            })}
          </PendingActionsCont>
        </SectionCont>
      ) : null}

      {/* NEW USER BANNER */}
      {dashboard?.create_campaign && (
        <BannerCont>
          <BannerContent>
            <BannerHeading>{dashboard.create_campaign.label}</BannerHeading>
            <BannerDescription>
              {dashboard.create_campaign.description}
            </BannerDescription>
            <Button
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                fontWeight: "600",
              }}
              text={dashboard.create_campaign.cta.label}
              type="primary"
              onClick={() => {
                navigate("/brand/create-campaign");
              }}
              iconPosition="start"
              icon={<img src={ICONS.plus} alt="plus" />}
            />
          </BannerContent>
          {/* <img src={newUserBannerImg} /> */}
        </BannerCont>
      )}

      {/* DEMO  */}
      <SectionCont>
        <SectionHeading>{dashboard?.demo.label}</SectionHeading>
        <SectionDescription>{dashboard?.demo.description}</SectionDescription>
        <DemoCont>
          {dashboard?.demo?.cards.map((curr, i) => {
            return (
              <DemoCard
                card={curr}
                key={i}
                onClick={() => {
                  if (curr.item_type === "blog") {
                    window.open(curr.url, "_target");
                  } else {
                    setVideoUrl(curr.url);
                  }
                }}
              />
            );
          })}
        </DemoCont>
      </SectionCont>
    </BrandHomePageCont>
  );
}

export default Home;

const PendingActionCard = ({ card }: { card: types.ActionCard }) => {
  const navigate = useNavigate();

  const pendingActionFunctions = {
    connect_instagram: () => {
      navigate("/brand/instagram-connect");
    },
  };

  const handleAction = (key: types.PendingActions) => {
    const action = pendingActionFunctions[key];
    action();
  };
  return (
    <PendingActionCardCont>
      <ActionIconCont>
        <img src={ICONS[card.icon]} alt="card-icon" width={"30px"} />
      </ActionIconCont>
      <ActionHeading>{card.title}</ActionHeading>
      <ActionDescription>{card.description}</ActionDescription>
      <Button
        style={{
          height: "40px",
          display: "flex",
          alignItems: "center",
          fontWeight: "600",
        }}
        text={card.cta.label}
        type="primary"
        onClick={() => handleAction(card.cta.key)}
        iconPosition="end"
        icon={<img src={ICONS.arrowRight} alt="arrow-right" />}
      />
    </PendingActionCardCont>
  );
};

const DemoCard = ({
  card,
  onClick,
}: {
  card: types.DemoCard;
  onClick: () => void;
}) => {
  return (
    <DemoCardCont onClick={onClick}>
      <DemoThumbnailCont>
        <img
          src={card.thumbnail}
          alt={card.description}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </DemoThumbnailCont>
      <DemoDescription>{card.title}</DemoDescription>
    </DemoCardCont>
  );
};
