import React, { useState, useImperativeHandle, forwardRef, Ref } from "react";
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import {
  publicRequest,
  privateRequest as axiosPrivateRequest,
} from "../../api-call";
import { ERROR_MESSAGES } from "../../Utilities/Http/http.utilities";

interface DataRendererProps<ResponseData, ErrorResponse = unknown> {
  queryKey: any;
  apiEndpoint: string;
  enabled?: boolean;
  qParams?: Record<string, any>;
  privateRequest?: boolean;
  queryDependencies?: any[];
  queryOptions?: UseQueryOptions<ResponseData, ErrorResponse>;
  onSuccess?: (data: ResponseData) => void;
  onError?: (error: ErrorResponse) => void;
  renderData: (data: ResponseData, error: string) => React.ReactNode;
  renderLoading?: () => React.ReactNode;
  renderError?: (props: { error: string }) => React.ReactNode;
  errorFallback?: React.ComponentType;
  extraLoadingParam?: boolean[];
}

const defaultRenderLoading = () => {
  return <div>Loading...</div>;
};
const defaultRenderError = ({ error }: { error: string }) => (
  <div>
    <p>{error}</p>
  </div>
);

const DefaultErrorFallback: React.FC = () => (
  <div>
    <p>{ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG}</p>
  </div>
);

const DataRenderer = <ResponseData, ErrorResponse = unknown>(
  props: DataRendererProps<ResponseData, ErrorResponse>,
  ref: Ref<{ refetch?: () => void }>
) => {
  const {
    enabled = true,
    apiEndpoint,
    qParams,
    privateRequest = false,
    queryKey,
    queryDependencies = [],
    queryOptions,
    onSuccess = () => {},
    onError = () => {},
    renderData,
    renderLoading = defaultRenderLoading,
    renderError = defaultRenderError,
    errorFallback: ErrorFallbackComponent = DefaultErrorFallback,
    extraLoadingParam = [],
  } = props;

  const [error, setError] = useState<string>("");

  const handleFetching = async (): Promise<ResponseData> => {
    try {
      const response = privateRequest
        ? await axiosPrivateRequest.get<
            | ResponseData
            | {
                data: {
                  error: string;
                };
              }
          >(apiEndpoint, {
            params: qParams,
          })
        : await publicRequest.get<
            | ResponseData
            | {
                data: {
                  error: string;
                };
              }
          >(apiEndpoint, {
            params: qParams,
          });

      // Check if response contains an error object
      // @ts-ignore
      if (response.data && response.data?.error) {
        // @ts-ignore
        const { status_code } = response.data.error;
        if (status_code && status_code >= 400 && status_code < 600) {
          setError(ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG);
          throw new Error(ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG);
        }
      }
      // @ts-ignore
      return response.data;
    } catch (err) {
      throw err; // Rethrow error for React Query to handle
    }
  };

  const {
    isLoading,
    data,
    error: queryError,
    isError,
    refetch,
  }: UseQueryResult<ResponseData, ErrorResponse> = useQuery<
    ResponseData,
    ErrorResponse
  >({
    queryKey: [queryKey, ...queryDependencies],
    queryFn: handleFetching,
    enabled,
    ...queryOptions,
  });

  React.useEffect(() => {
    if (isError && queryError) {
      setError(ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG);
      onError?.(queryError);
    }
  }, [isError, queryError, onError]);

  React.useEffect(() => {
    if (data) {
      // Check for errors in data before calling onSuccess
      // @ts-ignore
      if (data && data.error) {
        // @ts-ignore
        const { status_code, description } = data.error;
        if (status_code && status_code >= 400 && status_code < 600) {
          setError(description || ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG);
          return;
        }
      }
      setError("");
      onSuccess?.(data);
    }
  }, [data, onSuccess]);

  const isLoadingWithExtraParams = isLoading || extraLoadingParam.some(Boolean);

  useImperativeHandle(
    ref,
    () => ({
      refetch,
    }),
    [refetch]
  );

  return (
    <ErrorBoundary fallback={<ErrorFallbackComponent />}>
      {isLoadingWithExtraParams ? (
        <ErrorBoundary fallback={<ErrorFallbackComponent />}>
          {renderLoading()}
        </ErrorBoundary>
      ) : error ? (
        <ErrorBoundary fallback={<ErrorFallbackComponent />}>
          {renderError({ error })}
        </ErrorBoundary>
      ) : (
        <ErrorBoundary fallback={<ErrorFallbackComponent />}>
          {renderData(data as ResponseData, error)}
        </ErrorBoundary>
      )}
    </ErrorBoundary>
  );
};

// Use type assertion to preserve generics
const ForwardedDataRenderer = forwardRef(DataRenderer) as <
  ResponseData,
  ErrorResponse = unknown
>(
  props: DataRendererProps<ResponseData, ErrorResponse> & {
    ref?: Ref<{ refetch?: () => void }>;
  }
) => React.ReactElement;

export default ForwardedDataRenderer;
