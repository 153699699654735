import { http } from "../../Utilities";
import { ValueOf } from "../../Utilities/Types";
import { url } from "../../Utilities/Url";
import * as CONSTANTS from "./BrandApplications.constants";
import * as types from "./BrandApplications.types";

export function getCampaignApplications(campaignId: string, status: string) {
  return http.get<types.ApplicationListRawResponse>(
    CONSTANTS.API_ENDPOINTS.APPLICATIONS,
    { campaign_ids: campaignId, status }
  );
}

const PAGE_LENGTH = 10;

export const handleFetchApplications = async (
  campaignId: string,
  status: string,
  param: types.PageParam
): Promise<types.ApplicationsPaginationListResponse> => {
  const response = await http.get<types.ApplicationListRawResponse>(
    CONSTANTS.API_ENDPOINTS.APPLICATIONS,
    {
      campaign_ids: campaignId,
      status,
      pagination_offset: param.pageParam * PAGE_LENGTH,
      pagination_limit: PAGE_LENGTH,
    }
  );

  return {
    response,
    nextPage:
      response.result.applications && response.result.applications?.length > 0
        ? param.pageParam + 1
        : null,
  };
};

export function getApplicationData(
  applicationId: number,
  type: types.ApplicantType
) {
  return http.get<types.ApplicantRawResponse>(
    url.replacePathParams(CONSTANTS.API_ENDPOINTS.GET_APPLICATION, {
      applicationId,
    }),
    {
      type,
    }
  );
}

export function updateApplicationStatus({
  applicationId,
  status,
  rejectReason,
}: {
  applicationId: number;
  status: ValueOf<typeof CONSTANTS.APPLICATION_STATUS>;
  rejectReason?: types.RejectReason;
}) {
  return http.put<types.ApplicantRawResponse>(
    CONSTANTS.API_ENDPOINTS.APPLICATION,
    {
      ids: [applicationId],
      updates: { status, rejection_reasons: [rejectReason] },
    }
  );
}
