import styled from "styled-components";
import { Tabs, Text } from "../../Components";
import { STYLES } from "../../Constants";
import { BOX_SHADOWS, COLORS } from "../../Constants/styles.constants";

export const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
`;

export const PageTitle = styled(Text)``;

export const SubmissionTabs = styled(Tabs)`
  padding: 0 16px;
`;

export const PageContent = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 300px) 1fr;
  background-color: ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  border-left: 16px solid ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  margin-left: -16px;
  margin-right: -16px;
`;

export const FullPageContent = styled.div`
  height: calc(100vh - 50px - 70px);
  padding: 16px;
  overflow-y: auto;
  padding-left: 0;
  box-sizing: border-box;
  background-color: ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  padding: 12px;
  margin-left: -16px;
  margin-right: -16px;
`;

export const SubmissionListWrapper = styled.ul`
  height: calc(100vh - 50px - 70px);
  overflow-y: auto;
  padding: 16px;
  padding-left: 0;
  box-sizing: border-box;
`;

export const RejectedSubmissionListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
`;

type SubmissionItemWrapperProps = {
  $selected: boolean;
};

export const SubmissionItemWrapper = styled.li<SubmissionItemWrapperProps>`
  background-color: ${STYLES.COLORS.WHITE};
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;
  box-shadow: ${BOX_SHADOWS.primary};
  border: ${({ $selected }) =>
    $selected ? `2px solid ${COLORS.PRIMARY}` : "2px solid transparent"};
`;

export const SubmissionTitleWrapper = styled.div`
  display: flex;
`;

export const SubmissionBasicInfo = styled.div`
  padding-left: 12px;
`;

export const SubmissionBasicSecond = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LabelInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  padding-top: 12px;
`;

export const SubmissionProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${STYLES.COLORS.WHITE};
  border-radius: 8px;
  height: calc(100vh - 50px - 70px - 16px);
  overflow: hidden;
  position: relative;
`;

export const SubmissionProfileContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const SubmissionHeaderWrapper = styled.div``;

export const SubmissionHeaderContent = styled.div`
  gap: 8px;
  align-items: center;
`;

export const SubmissionMainWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  flex: 1;
  margin-bottom: 12px;
  grid-template-rows: max-content;
  grid-row-gap: 12px;
  overflow: auto;
  padding: 12px;
`;

export const VideoPlayer = styled.video`
  border-radius: 8px;
  max-height: 500px;
`;

export const SubmissionContentWrapper = styled.div`
  background-color: ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  padding: 12px;
  border-radius: 8px;
`;

export const CaptionTitle = styled(Text)`
  padding-top: 12px;
  padding-bottom: 8px;
`;

export const SubmissionActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  border-top: 1px solid ${STYLES.COLORS.BORDER_COLOR};
`;

export const SubmissionFooterWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 12px;
  border-top: 1px solid ${STYLES.COLORS.BORDER_COLOR};
`;

export const SubmissionFeedbackViewWrapper = styled.div`
  border: 1px solid ${STYLES.COLORS.BORDER_COLOR};
  border-radius: 8px;
  background-color: ${STYLES.COLORS.PRIMARY_LIGHT_BACKGROUND_COLOR};
  max-height: 600px;
  overflow-y: auto;
`;

export const FeedbackViewHeader = styled.div`
  background-color: ${STYLES.COLORS.WHITE};
  padding: 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const FeedbackCommentsWrapper = styled.div`
  padding: 12px;
`;

export const SubmissionCommentWrapper = styled.div`
  background-color: ${STYLES.COLORS.WHITE};
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
`;

export const CommenterMetaInfo = styled.div`
  display: flex;
  padding-bottom: 12px;
  gap: 8px;
  align-items: center;
`;

export const BaseCommentWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  gap: 12px;
  align-items: start;
  padding-bottom: 12px;
  padding-top: 12px;
`;

export const ReplyButtonWrapper = styled.div`
  padding-top: 12px;
  padding-left: 48px;
`;

export const ReplySubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  gap: 12px;
`;

export const RequestChangesMainWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-bottom: 12px;
  width: 100%;
`;

export const FirstFeedbackCommentSubmit = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FirstFeedbackCommentItem = styled.div`
  background-color: ${STYLES.COLORS.WHITE};
  border-radius: 8px;
  margin-bottom: 12px;
  padding-left: 12px;
`;

export const VersionSelectWrapper = styled.div`
  display: flex;
  gap: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const VersionOptionsImage = styled.img`
  width: 40px;
`;

export const RequestChangeActionsWrapper = styled.div`
  border-top: 1px solid ${STYLES.COLORS.BORDER_COLOR};
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
`;

export const RequestChangeActionContent = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`;

export const RejectReasonWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ApproveLiveContentWrapper = styled.div`
  height: 100%;
`;

export const ApproveLiveContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  height: 100%;
`;

const ApproveContentSection = styled.div`
  padding: 12px;
  background-color: ${STYLES.COLORS.PRIMARY_LIGHT_BACKGROUND_COLOR};
  border-radius: 8px;
  height: 100%;
`;

export const ApproveContentLeftSection = styled(ApproveContentSection)``;

export const ApproveContentRightSection = styled(ApproveContentSection)``;

export const CheckboxContentWrapper = styled.div`
  padding: 12px;
  padding-bottom: 2px;
  background-color: ${STYLES.COLORS.WHITE};
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const ApproveLiveActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const WrongLinkHintText = styled(Text)`
  padding-bottom: 32px;
`;

export const SubmissionContentInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 12px;
`;

export const NextActionWrapper = styled.div`
  padding: 8px;
  background-color: ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  border-radius: 8px;
  margin-top: 12px;
`;

export const ApplicationSecondaryInfo = styled.div`
  padding-top: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const SubmissionRejectReasonWrapper = styled.div`
  background-color: ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
`;

export const HastTagText = styled(Text)`
  padding-top: 8px;
`;
