import { Tooltip } from "react-tooltip";

import * as types from "./LabelValueCard.types";
import ContainarizedImage from "../ContainarizedImage/ContainarizedImage";
import { COLORS } from "../../Constants/styles.constants";

const LabelValueCard: React.FC<types.LabelValueCardProps> = ({
  icon,
  label,
  value,
  contStyle,
  remainingItems,
}) => {
  const remainingLength = remainingItems ? remainingItems.length : 0;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "16px",
        ...contStyle,
      }}
    >
      <div
        style={{
          border: `1.5px solid ${COLORS.GREY100}`,
          padding: "8px",
          borderRadius: 10,
          marginRight: "8px",
        }}
      >
        <ContainarizedImage
          src={icon}
          height={"24px"}
          width={"24px"}
          alt="icon"
        />
      </div>
      <div>
        <p
          style={{
            fontSize: "12px",
            lineHeight: "16px",
            color: "#000",
            fontWeight: "500",
          }}
        >
          {label}
        </p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#000",
              lineHeight: "24px",
            }}
          >
            {value}
          </p>
          {remainingLength > 0 && (
            <div
              data-tooltip-id="my-tooltip"
              data-tooltip-content={remainingItems?.join(", ")}
              style={{
                color: COLORS.PRIMARY,
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
              }}
            >
              +{remainingLength}
              <Tooltip id="my-tooltip" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LabelValueCard;
