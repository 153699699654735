import axios from "axios";
import { AUTH_TOKEN_LOCAL_STORAGE_KEY } from "./Constants/auth.constants";

const RYME_API_URL =
  process.env.NODE_ENV === "development"
    ? "https://beta.ryme.ai/api"
    : `https://${window.location.hostname}/api`;

const logout = async () => {
  try {
    localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
    window.location.reload();
  } catch (error) {
    console.error("Error during logout:", error);
  }
};

// Public request instance
export const publicRequest = axios.create({
  baseURL: RYME_API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Private request instance
export const privateRequest = axios.create({
  baseURL: RYME_API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor for attaching token from localStorage
privateRequest.interceptors.request.use(
  async (config) => {
    try {
      const token = localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        throw new Error("No token exists");
      }
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 errors
privateRequest.interceptors.response.use(
  (response) => {
    if (response && response.data.error?.status_code === 401) {
      logout();
    }
    return response;
  },
  async (error) => {
    const { response } = error;
    if (response && response.data.error?.status_code === 401) {
      console.error("Unauthorized - Token might be expired or invalid");
      logout();
    }

    // For other errors, reject the error as usual
    return Promise.reject(error);
  }
);
