import StatusCorrect from "./StatusCorrect.svg";
import StatusWrong from "./StatusWrong.svg";
import home from "./home.svg";
import megaphone from "./megaphone.svg";
import settings from "./settings.svg";
import logout from "./logout.svg";
import ThreeDot from "./ThreeDot.svg";
import Google from "./Google.svg";
import image_55 from "./image_55.svg";
import image_56 from "./image_56.svg";
import arrowRight from "./arrow-right.svg";
import arrowLeft from "./arrow-left.svg";
import plus from "./plus.svg";
import microphone from "./microphone-stand.svg";
import gender from "./gender.svg";
import locationPin from "./location-pin.svg";
import arrowsExpand from "./arrows-expand.svg";
import comment from "./comment.svg";
import share from "./share.svg";
import bookmark from "./bookmark.svg";
import heart from "./heart.svg";
import onboardingLine from "./onboarding-line.svg";
import check from "./check.png";
import whiteHome from "./white-home.svg";
import whiteLogout from "./white-logout.svg";
import whiteMegaPhone from "./white-megaphone.svg";
import whiteUser from "./whiteuser-square.svg";
import user from "./user-square.svg";
import arrowRightPrimary from "./arrow-right-primary.svg";
import commentExclaimation from "./comment-exclamation.svg";
import angleRight from "./angle-right.svg";
import userPrimary from "./User.svg";
import info from "./info.png";
import arrowReply from "./arrow-reply.svg";
import threeDot from "./ThreeDot.svg";
import close from "./Close.png";
import link from "./Link.svg";
import clock from "./clock.svg";
import angleUpDown from "./angles-down-up.svg";
import sortVertical from "./sort-vertical.svg";
import copy from "./copy.png";
import edit from "./edit.png";

export const ICONS = {
  StatusCorrect,
  StatusWrong,
  home,
  megaphone,
  settings,
  logout,
  ThreeDot,
  Google,
  image_55,
  image_56,
  arrowRight,
  arrowLeft,
  plus,
  microphone,
  gender,
  arrowRightPrimary,
  locationPin,
  arrowsExpand,
  comment,
  share,
  bookmark,
  heart,
  onboardingLine,
  check,
  whiteHome,
  whiteLogout,
  info,
  whiteMegaPhone,
  whiteUser,
  user,
  angleRight,
  commentExclaimation,
  userPrimary,
  threeDot,
  arrowReply,
  close,
  clock,
  link,
  angleUpDown,
  sortVertical,
  copy,
  edit,
};
