import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Login from "../Pages/Login";
import ForgotPassword from "../Pages/ForgotPassword";
import RequestForgotPassword from "../Pages/RequestForgotPassword";
import Brand from "../Pages/Brand";
import BrandCampaignForm from "../Pages/BrandCampaignForm";
import CreateCampaign from "../Pages/CreateCampaign";
import BrandApplications from "../Pages/BrandApplications";
import BrandCampaigns from "../Pages/BrandCampaigns";
import BrandSubmissions from "../Pages/BrandSubmissions";
import BrandProposal from "../Pages/BrandProposal";
import BrandInsights from "../Pages/BrandInsights";
import VerifyEmail from "../Pages/VerifyEmail";
import { AUTH } from "../Constants";
import {
  AuthLayoutWrapper,
  LayoutHelperText,
  LayoutHeroImage,
  LayoutLeft,
  LayoutRight,
  LogoImage,
} from "./Router.styles";
import Logo from "../Assets/Images/logo.png";
import HorizontalLogo from "../Assets/Images/logo_horizontal.png";
import { Text } from "../Components";
import BrandHome from "../Pages/BrandHome";
import FBConnect from "../Pages/FBConnect";
import DontAllowAuthenticated from "../Pages/DontAllowAuthenticated";
import AboutBusiness from "../Pages/AboutBusiness/AboutBusiness";
import Onboarding from "../Navigation/Onboarding/Onboarding";
import ContactDetails from "../Pages/ContactDetails/ContactDetails";
import { CHILD_PATHS } from "../Pages/Brand/Brand.constants";
import BrandProfile from "../Pages/BrandProfile/BrandProfile";
import CampaignDetailsPage from "../Pages/CampaignDetailsPage/CampaignDetailsPage";
import styled from "styled-components";
import { COLORS } from "../Constants/styles.constants";

function HomeRedirect() {
  const token = localStorage.getItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY);
  return <Navigate to={token ? "/brand/home" : "/login"} />;
}

const DesktopOutletCont = styled.div`
  background-color: ${COLORS.WHITE};
  @media (max-width: 2400px) {
    width: 45vw;
    overflow: auto;
    box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
    height: 100vh;
    overflow: auto;
  }
  @media (max-width: 1100px) {
    width: 65vw;
    overflow: auto;
    box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
    height: 100vh;
    overflow: auto;
  }
  @media (max-width: 980px) {
    width: 100%;
    overflow: auto;
    box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
    height: 100vh;
    overflow: auto;
  }
`;
function AuthLayout() {
  return (
    <AuthLayoutWrapper>
      <LayoutLeft>
        <LayoutHeroImage src={Logo} alt="Ryme Logo" />
        <Text fontSize="XXX_LARGE" fontWeight="SEMI_BOLD" lineHeight="1">
          Unleash authentic brand storytelling with ryme.ai
        </Text>
        <LayoutHelperText fontWeight="SEMI_BOLD">
          Login to find your perfect influencer match.
        </LayoutHelperText>
      </LayoutLeft>
      <LayoutRight>
        <LogoImage src={HorizontalLogo} />
        <Outlet />
      </LayoutRight>
    </AuthLayoutWrapper>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <DontAllowAuthenticated>
        <AuthLayout />
      </DontAllowAuthenticated>
    ),
    children: [
      {
        path: "/",
        element: <HomeRedirect />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <RequestForgotPassword />,
      },
      {
        path: "/forgot-password/:token",
        element: <ForgotPassword />,
      },
      {
        path: "/verify-email/:token?",
        element: <VerifyEmail />,
      },
    ],
  },
  {
    path: "/onboarding",
    element: <Onboarding />,
    children: [
      {
        path: CHILD_PATHS.aboutBusiness,
        element: <AboutBusiness />,
      },
      {
        path: CHILD_PATHS.contactDetails,
        element: <ContactDetails />,
      },
    ],
  },
  {
    path: "/brand/",
    element: <Brand />,
    children: [
      {
        path: "/brand/form",
        element: <BrandCampaignForm />,
      },
      {
        path: "/brand/create-campaign",
        element: <CreateCampaign />,
      },
      {
        path: "/brand/campaigns",
        element: <BrandCampaigns />,
      },
      {
        path: "/brand/home",
        element: <BrandHome />,
      },
      {
        path: "/brand/instagram-connect",
        element: <FBConnect />,
      },
      {
        path: "/brand/campaigns",
        element: <BrandCampaigns />,
      },
      {
        path: "/brand/campaigns/:campaignId/applications",
        element: <BrandApplications />,
      },
      {
        path: "/brand/campaigns/:campaignId/submissions",
        element: <BrandSubmissions />,
      },
      {
        path: "/brand/campaigns/:campaignId/proposal",
        element: <BrandProposal />,
      },
      {
        path: "/brand/campaigns/:campaignId/insights",
        element: <BrandInsights />,
      },
      {
        path: "/brand/campaign-details/:campaignId",
        element: (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              backgroundColor: COLORS.GREY50,
            }}
          >
            <DesktopOutletCont>
              <CampaignDetailsPage />
            </DesktopOutletCont>
          </div>
        ),
      },
      {
        path: "/brand/profile",
        element: <BrandProfile />,
      },
    ],
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
