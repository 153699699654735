import { format, formatRelative } from "date-fns";
import { toZonedTime } from "date-fns-tz";

export function formatDayMonthYear(dateTime: string) {
  if (!dateTime) return "";
  return format(new Date(dateTime), "dd/MM/yyyy");
}

export function formatDayMonthYearTime(dateTime: string) {
  if (!dateTime) return "";
  return format(new Date(dateTime), "dd/MM/yyyy HH:mm");
}

/**
 *  Function will take in seconds and convert it to a readable format
 * @param seconds
 * @returns string
 */

export function formatSecondsToTime(seconds: number) {
  if (!seconds) return "0:00";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
  }`;
}

/**
 * Function will take in date and time and convert it to relative time
 * @param dateTime
 * @returns string
 */

export function formatRelativeTime(dateTime: string): string {
  if (!dateTime) return "";
  try {
    const date = new Date(dateTime);
    const istDate = toZonedTime(date, "Asia/Kolkata");
    return formatRelative(istDate, toZonedTime(new Date(), "Asia/Kolkata"));
  } catch (error) {
    return "";
  }
}

export function formatDateTime(isoString: string): string {
  if (!isoString) return "";
  try {
    const istDate = toZonedTime(new Date(isoString), "Asia/Kolkata");
    const day = istDate.getDate().toString().padStart(2, "0");
    const month = (istDate.getMonth() + 1).toString().padStart(2, "0");
    const year = istDate.getFullYear();
    let hours = istDate.getHours();
    const minutes = istDate.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${day}-${month}-${year} ${hours}:${minutes} ${amPm}`;
  } catch (error) {
    return "";
  }
}
