import { useRef } from "react";
import { ServerResponseData } from "../../Utilities/Types";
import * as types from "./CampaignDetailsPage.types";
import { useNavigate, useParams } from "react-router-dom";
import CustomNav from "../../Components/CustomNav/CustomNav";
import DataRenderer from "../../Components/DataRenderer/DataRenderer";

import {
  CampaignDescriptionSection,
  CompensationSection,
  DosAndDonts,
  EligiblilitySection,
  Resources,
  SummaryComponent,
} from "./CampaignDetailsPage.sub";
import { COLORS } from "../../Constants/styles.constants";

const CampaignDetailsPage = () => {
  const navigate = useNavigate();
  const campaignDetailsRef = useRef<any>();

  // Access campaignId directly from useParams
  const { campaignId } = useParams<{ campaignId: string }>();

  const ENDPOINTS = {
    campaign: "/client/partner/campaign",
  };

  return (
    <DataRenderer<ServerResponseData<types.CampaignAPIResult>>
      queryKey={`campaign-${campaignId}`}
      privateRequest
      apiEndpoint={`${ENDPOINTS.campaign}/${campaignId}`}
      renderData={({ result: campaignData }) => {
        return (
          <div style={{ backgroundColor: COLORS.WHITE, color: COLORS.GREY500 }}>
            <CustomNav
              title={campaignData.brand.name}
              goBack={() => navigate(-1)}
              withBorder
              // navRight={
              //   <div
              //     onClick={() => setShowShare(true)}
              //     style={{ cursor: "pointer" }}
              //   >
              //     <ContainarizedImage
              //       src={share}
              //       height={"2.4rem"}
              //       width={"2.4rem"}
              //     />
              //   </div>
              // }
            />
            <SummaryComponent
              brandName={campaignData.brand.name}
              endDate={campaignData.campaign.application_deadline}
              campaignTitle={campaignData.campaign.title}
              campaignDescription={campaignData.campaign.description}
              prize={
                campaignData.campaign.prizes
                  ? campaignData.campaign.prizes?.flat
                  : null
              }
              totalSlots={campaignData.campaign.total_slots}
              remainingSlots={campaignData.campaign.remaining_slots}
              compensationtype={campaignData.campaign.compensation_type}
              platforms={campaignData.campaign.platforms}
              brandDescription={campaignData.brand.description}
              tagLine={campaignData.brand.tag_line}
              website={campaignData.brand.website}
              links={campaignData.brand.links}
              brandLogo={campaignData.brand.logo}
            />
            <EligiblilitySection
              eligibility={campaignData.campaign.eligibility}
            />
            <CampaignDescriptionSection
              ageGroup={campaignData.campaign.target_audience.age_ranges}
              locations={campaignData.campaign.target_audience.locations}
              gender={campaignData.campaign.target_audience.gender}
              campaignDescription={campaignData.campaign.description}
              deliverables={campaignData.campaign.deliverables}
            />
            <CompensationSection
              compensationType={campaignData.campaign.compensation_type}
              prize={
                campaignData.campaign.prizes
                  ? campaignData.campaign.prizes?.flat
                  : null
              }
            />
            <Resources
              caption={campaignData.campaign.caption}
              hashtags={campaignData.campaign.hashtags}
            />
            <DosAndDonts rules={campaignData.campaign.rules} />,
          </div>
        );
      }}
      ref={campaignDetailsRef}
    />
  );
};

export default CampaignDetailsPage;
