const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // Needed to avoid cross-origin issues
    image.src = url;
  });

interface PixelCrop {
  x: number;
  y: number;
  width: number;
  height: number;
}

const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: PixelCrop
): Promise<string> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Failed to get canvas context");
  }

  // Calculate safe area to ensure image rotation does not cause empty areas
  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // Create a canvas large enough to handle the safe area
  canvas.width = safeArea;
  canvas.height = safeArea;

  // Fill the canvas with a white background
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Draw the full image in the center of the safe area
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  // Extract the pixel data of the safe area
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // Resize the canvas to the crop dimensions
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // Fill the resized canvas with a white background
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Draw the cropped image onto the resized canvas
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // Return the cropped image as a base64 string
  return canvas.toDataURL("image/jpeg");
};

export const cropImage = async (
  image: string,
  croppedAreaPixels: PixelCrop,
  onError: (error: unknown) => void
): Promise<string | undefined> => {
  try {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    return croppedImage;
  } catch (err) {
    onError(err);
  }
};
