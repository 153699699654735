import styled from "styled-components";
import { COLORS } from "../../Constants/styles.constants";

export const SummaryContainer = styled.div`
  margin-top: 30px;
  padding: 0 30px;
`;

export const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const BrandRow = styled.div`
  display: flex;
  align-items: center;
`;

export const BrandName = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #000;
  margin-left: 9px;
  font-weight: 500;
`;

export const InfoButton = styled.button`
  padding: 10px;
  background-color: ${COLORS.GREY50};
  border-radius: 10px;
  border: 0;
`;

export const TimelineContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const DaysLeft = styled.span`
  padding: 4px 8px;
  margin-right: 8px;
  border-radius: 4px;
  font-size: 14px;
`;

export const CampaignContainer = styled.div`
  margin-bottom: 16px;
`;

export const CampaignTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
  color: #000;
  margin-bottom: 4px;
  font-weight: 500;
`;

export const OtherInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  border-radius: 8px;
  background-color: ${COLORS.PURPLE_CARD};
`;

export const InfoBox = styled.div``;

export const Separator = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${COLORS.GREY200};
`;

export const Label = styled.p`
  font-size: 12px;
  margin-bottom: 4px;
`;

export const Value = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #000;
`;

export const PlatformIcons = styled.div`
  display: flex;
  align-items: center;
`;

export const FloatingButtonContainer = styled.div`
  background-color: ${COLORS.WHITE};
  z-index: 100;
  height: 80px;
  position: absolute;
  max-width: 65vw;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  bottom: 0;
  padding: 10px 30px;
  box-shadow: 0px -4px 8px 0px #00000009;

  @media (max-width: 2400px) {
    width: 45vw;
  }
  @media (max-width: 1100px) {
    width: 65vw;
  }
  @media (max-width: 980px) {
    width: 100%;
    position: fixed;
    max-width: 100%;
  }
`;

export const ClosesOnText = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
`;

export const DateText = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
`;
