import React, { Component, ReactNode } from "react";
import { ERROR_MESSAGES } from "../../Utilities/Http/http.utilities";

interface ErrorBoundaryProps {
  fallback: ReactNode;
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.errorContainer}>
          <p style={styles.errorMessage}>
            {this.state.error?.message ||
              ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG}
          </p>
          {this.props.fallback}
        </div>
      );
    }

    return this.props.children;
  }
}

const styles = {
  errorContainer: {
    padding: "20px",
    border: "1px solid red",
    backgroundColor: "#ffe6e6",
    borderRadius: "5px",
    color: "red",
  },
  errorMessage: {
    fontWeight: "bold",
  },
};

export default ErrorBoundary;
